import { useAtom } from "jotai"
import { forwardRef, useState } from "react"
import PropTypes from "prop-types"
import DatePicker from "react-datepicker"
import CollapseCard from "../../../components/CollapseCard"
import {
  acceptInputNumbersOnly,
  handleNumericStateChange,
  handleFloatOnBlurStateChange,
  handleTextStateChange,
  selectAllOnFocus,
  formatDate
} from "../../../utils"
import {
  THERAPY_TYPES,
  THERMAL_COMFORT_TYPES,
  USER_TYPES,
  IMMERSION_TYPES
} from "../../../constants"
import {
  publicProtocolFormStateAtom,
  isSubmittingPublicProtocolAtom,
  canComputePublicProtocolAtom
} from "./public-protocol-ui-state"
import { publicSectionProtocolStateAtom } from "../public-session-ui-state"
import { toast } from "react-toastify"
import { convertSecondsToMinAndSecs } from "../../session/TimerSection/protocol-calculator"
import {
  publicBodyImmersionTimeAtom,
  publicImmersionTimeAtom,
  publicTimerStateAtom
} from "../PublicTimerSection/public-timer-ui-state"
import { CreateNewPublicSession } from "../../../components/user/user"
import { CurrentPublicSession } from "../../../models/public-session"
// import { convertSecondsToMinAndSecs } from "../TimerSection/protocol-calculator"
// import { toast } from "react-toastify"
// import { useState } from "react"

// function to compute bmi
function computeBMI(weightKG, heightM) {
  return weightKG / (heightM * heightM)
}

function computePercentFatMass(weightKG, heightM, gender) {
  const bmi = computeBMI(weightKG, heightM)

  if (bmi < 18.5) {
    if (gender === "gender-m") {
      return (5 + 2) / 2
    } else {
      return (10 + 13) / 2
    }
  } else if (bmi < 25) {
    if (gender === "gender-m") {
      return (6 + 13) / 2
    } else {
      return (14 + 20) / 2
    }
  } else if (bmi < 30) {
    if (gender === "gender-m") {
      return (14 + 17) / 2
    } else {
      return (21 + 24) / 2
    }
  } else if (bmi < 35) {
    if (gender === "gender-m") {
      return (18 + 24) / 2
    } else {
      return (25 + 31) / 2
    }
  } else {
    if (gender === "gender-m") {
      return 25
    } else {
      return 32
    }
  }
}

const PublicProtocolSection = ({ email, transitionToNextStage }) => {
  const [sectionState, setSectionState] = useAtom(
    publicSectionProtocolStateAtom
  )
  const [protocolFormState, setProtocolFormState] = useAtom(
    publicProtocolFormStateAtom
  )
  const [isSubmittingProtocol, setIsSubmittingProtocol] = useAtom(
    isSubmittingPublicProtocolAtom
  )

  const [canComputeProtocol] = useAtom(canComputePublicProtocolAtom)

  const [errorMessage, setErrorMessage] = useState("")

  const [, setImmersionTime] = useAtom(publicImmersionTimeAtom)
  const [, setBodyImmersionTime] = useAtom(publicBodyImmersionTimeAtom)

  const [, setCurrentPublicSession] = useAtom(CurrentPublicSession)

  const setTimerState = useAtom(publicTimerStateAtom)[1]

  const handleCollapsedChanged = (event) => {
    setSectionState({ ...sectionState, collapsed: event.target.checked })
  }

  if (sectionState.visible === false) {
    // setIsSubmittingProtocol(false)
    // setErrorMessage("")
    return <></>
  }

  const handleFloatOnBlur = (fieldName) => {
    handleFloatOnBlurStateChange(
      fieldName,
      protocolFormState,
      setProtocolFormState
    )
  }

  const handleNumericChange = (event) => {
    handleNumericStateChange(event, protocolFormState, setProtocolFormState)
  }

  const handleChange = (event) => {
    handleTextStateChange(event, protocolFormState, setProtocolFormState)
  }

  const handleDOBChange = (newDate) => {
    protocolFormState.dateOfBirth = formatDate(newDate)
    setProtocolFormState({ ...protocolFormState })
  }

  const handleSubmit = async (event) => {
    event.preventDefault()
    if (canComputeProtocol) {
      setIsSubmittingProtocol(true)
      setErrorMessage("")

      const date = new Date()
      const age =
        date.getFullYear() -
        new Date(protocolFormState.dateOfBirth).getFullYear()

      let percentFatMass = computePercentFatMass(
        protocolFormState.weight,
        protocolFormState.height / 100.0,
        protocolFormState.gender
      )

      if (percentFatMass < 5) {
        percentFatMass = 5
      } else if (percentFatMass > 35) {
        percentFatMass = 35
      }

      const protocolComputeOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          data: {
            age,
            percentFatMass: Math.round(percentFatMass),
            waterTemp: Math.round(protocolFormState.waterTemperature),
            therapyType: protocolFormState.therapyType,
            thermalComfort: protocolFormState.thermalComfort,
            userType: protocolFormState.userType,
            immersionType: protocolFormState.immersionType
          }
        })
      }

      const res = await fetch(
        process.env.REACT_APP_PROTOCOL_COMPUTE_URL,
        protocolComputeOptions
      )
      const resData = await res.json()
      if (!res.ok || resData.error) {
        setIsSubmittingProtocol(false)
        toast("Errore nel calcolo protocollo", { type: "error" })
        setErrorMessage(
          "Errore nel calcolo protocollo immersione, controllare la connessione internet o notificare QRYO."
        )
        console.log("Protocol compute error:", resData)
      } else {
        setIsSubmittingProtocol(false)
        setErrorMessage("")

        const computedImmersionTime = convertSecondsToMinAndSecs(
          resData.immersionTime
        )
        setImmersionTime(computedImmersionTime)
        setBodyImmersionTime({
          lowerBodyTime: resData.lower_body_time,
          upperBodyTime: resData.upper_body_time
        })

        const newTimerState = {
          minutesRemaining: computedImmersionTime.minutes,
          secondsRemaining: computedImmersionTime.seconds,
          state: "NOT_STARTED"
        }

        setTimerState(newTimerState)

        const session = await CreateNewPublicSession(email, {
          age,
          ...protocolFormState,
          immersionProtocolTime: resData.immersionTime,
          bodyImmersionTimes: {
            lowerBodyTime: resData.lower_body_time,
            upperBodyTime: resData.upper_body_time
          },
          percentFatMass,
          bmi: computeBMI(
            protocolFormState.weight,
            protocolFormState.height / 100.0
          )
        })

        handleProtocolDataServerResponse(session)
      }
    }
  }

  const handleProtocolDataServerResponse = (session) => {
    setIsSubmittingProtocol(false)
    transitionToNextStage()

    if (session) {
      toast("Dati protocollo salvati", { type: "info" })
      setCurrentPublicSession(session)
    } else {
      toast("Errore nel salvataggio dati protocollo", { type: "error" })
    }
  }

  const thermalComfortOptions = THERMAL_COMFORT_TYPES.map(
    ({ value, label }, index) => {
      return (
        <option key={index} value={value}>
          {label}
        </option>
      )
    }
  )

  const userTypeOptions = USER_TYPES.map(({ value, label }, index) => {
    return (
      <option key={index} value={value}>
        {label}
      </option>
    )
  })

  const immersionTypeOptions = IMMERSION_TYPES.map(
    ({ value, label }, index) => {
      return (
        <option key={index} value={value}>
          {label}
        </option>
      )
    }
  )

  const therapyTypeOptions = THERAPY_TYPES.map(({ value, label }, index) => {
    return (
      <option key={index} value={value}>
        {label}
      </option>
    )
  })

  const date = new Date()
  // compute the years difference between the current date and the birth date
  const age =
    date.getFullYear() - new Date(protocolFormState.dateOfBirth).getFullYear()

  const CustomDateInput = forwardRef(({ value, onClick }, ref) => (
    <input
      type="text"
      ref={ref}
      onClick={onClick}
      defaultValue={value}
      className="input input-bordered"
      disabled={sectionState.completed}
      readOnly
    />
  ))

  CustomDateInput.displayName = "CustomDateInput"
  CustomDateInput.propTypes = {
    value: PropTypes.string,
    onClick: PropTypes.func
  }

  return (
    <CollapseCard
      isCollapsed={sectionState.collapsed}
      isCompleted={sectionState.completed}
      handleCollapsedChanged={handleCollapsedChanged}
      title={"Calcolo Protocollo QRYO"}
    >
      <form onSubmit={handleSubmit}>
        <div className="form-control">
          <label className="label">
            <span className="label-text">Altezza (cm)</span>
          </label>
          <input
            id="height"
            name="height"
            type="number"
            required
            placeholder="cm"
            min={60}
            max={300}
            step={1}
            onChange={handleNumericChange}
            onBlur={handleFloatOnBlur}
            value={protocolFormState.height}
            disabled={sectionState.completed}
            className="input input-bordered"
            onFocus={selectAllOnFocus}
            onKeyPress={acceptInputNumbersOnly}
          />
        </div>
        <div className="form-control">
          <label className="label">
            <span className="label-text">Peso (kg)</span>
          </label>
          <input
            id="weight"
            name="weight"
            type="number"
            required
            placeholder="kg"
            min={20}
            max={300}
            step={0.1}
            onChange={handleNumericChange}
            onBlur={handleFloatOnBlur}
            value={protocolFormState.weight}
            disabled={sectionState.completed}
            className="input input-bordered"
            onFocus={selectAllOnFocus}
            onKeyPress={acceptInputNumbersOnly}
          />
        </div>
        <div className="form-control">
          <label className="label">
            <span className="label-text">Data di nascita ({age} anni)</span>
          </label>

          <DatePicker
            selected={new Date(protocolFormState.dateOfBirth)}
            onChange={handleDOBChange}
            peekNextMonth
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
            customInput={<CustomDateInput />}
            locale="it"
            dateFormat="dd MMM yyyy"
          />
        </div>
        <div className="form-control">
          <label className="label">
            <span className="label-text">Genere</span>
          </label>
          <label className="label">
            <span className="label-text">Uomo</span>
            <input
              id="gender-m"
              name="gender"
              type="radio"
              required
              onChange={handleChange}
              value="gender-m"
              checked={protocolFormState.gender === "gender-m"}
              disabled={sectionState.completed}
              className="radio"
            />
          </label>
          <label className="label">
            <span className="label-text">Donna</span>
            <input
              id="gender-f"
              name="gender"
              type="radio"
              required
              onChange={handleChange}
              value="gender-f"
              checked={protocolFormState.gender === "gender-f"}
              disabled={sectionState.completed}
              className="radio"
            />
          </label>
        </div>

        <div className="form-control">
          <label className="label">
            <span className="label-text">Temperatura Stanza (C)</span>
          </label>
          <input
            id="roomTemperature"
            name="roomTemperature"
            type="number"
            required
            placeholder="C"
            min={0}
            max={60}
            step={0.1}
            onChange={handleNumericChange}
            onBlur={handleFloatOnBlur}
            value={protocolFormState.roomTemperature}
            disabled={sectionState.completed}
            className="input input-bordered"
            onFocus={selectAllOnFocus}
            onKeyPress={acceptInputNumbersOnly}
          />
        </div>
        <div className="form-control">
          <label className="label">
            <span className="label-text">Temperatura Acqua Vasca (C)</span>
          </label>
          <input
            id="waterTemperature"
            name="waterTemperature"
            type="number"
            required
            placeholder="C"
            min={0}
            max={40}
            step={0.1}
            onChange={handleNumericChange}
            onBlur={handleFloatOnBlur}
            value={protocolFormState.waterTemperature}
            disabled={sectionState.completed}
            className="input input-bordered"
            onFocus={selectAllOnFocus}
            onKeyPress={acceptInputNumbersOnly}
          />
        </div>
        <div className="form-control">
          <label className="label">
            <span className="label-text">Comfort termico utente</span>
          </label>
          <select
            id="thermalComfort"
            name="thermalComfort"
            type="select"
            required
            onChange={handleChange}
            className="select select-bordered"
            value={protocolFormState.thermalComfort}
            disabled={sectionState.completed}
          >
            <option disabled value="">
              Scegliere comfort termico
            </option>
            {thermalComfortOptions}
          </select>
        </div>
        <div className="form-control">
          <label className="label">
            <span className="label-text">Tipo di terapia</span>
          </label>
          <select
            id="therapyType"
            name="therapyType"
            type="select"
            required
            onChange={handleChange}
            className="select select-bordered"
            value={protocolFormState.therapyType}
            disabled={sectionState.completed}
          >
            <option disabled value="">
              Scegliere terapia
            </option>
            {therapyTypeOptions}
          </select>
        </div>
        <div className="form-control">
          <label className="label">
            <span className="label-text">Tipo di utente</span>
          </label>
          <select
            id="userType"
            name="userType"
            type="select"
            required
            onChange={handleChange}
            className="select select-bordered"
            value={protocolFormState.userType}
            disabled={sectionState.completed}
          >
            <option disabled value="">
              Scegliere tipo utente
            </option>
            {userTypeOptions}
          </select>
        </div>
        <div className="form-control">
          <label className="label">
            <span className="label-text">Tipo di immersione</span>
          </label>
          <select
            id="immersionType"
            name="immersionType"
            type="select"
            required
            onChange={handleChange}
            className="select select-bordered"
            value={protocolFormState.immersionType}
            disabled={sectionState.completed}
          >
            <option disabled value="">
              Scegliere tipologia immersione
            </option>
            {immersionTypeOptions}
          </select>
        </div>

        {errorMessage.length > 0 && (
          <p className="text-error mt-4">{errorMessage}</p>
        )}
        {!sectionState.completed && (
          <div className="flex mt-4 gap-2">
            <button
              type="submit"
              className={
                "btn btn-block flex-1" +
                (canComputeProtocol ? " btn-primary" : "") +
                (isSubmittingProtocol ? " loading" : "")
              }
              disabled={!canComputeProtocol}
            >
              {isSubmittingProtocol
                ? "Salvataggio Protocollo Immersione"
                : "Calcola Protocollo Immersione"}
            </button>
          </div>
        )}
      </form>
    </CollapseCard>
  )
}

PublicProtocolSection.propTypes = {
  email: PropTypes.string,
  transitionToNextStage: PropTypes.func
}

export default PublicProtocolSection
