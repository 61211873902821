import { atom } from "jotai"

export const PROTOCOL_FORM_INITIAL_STATE = {
  roomTemperature: 25,
  waterTemperature: 10,
  userType: "",
  therapyType: "",
  thermalComfort: "",
  immersionType: ""
}

export const protocolFormStateAtom = atom({ ...PROTOCOL_FORM_INITIAL_STATE })

export const isSubmittingProtocolAtom = atom(false)

export const protocolDataIsComplete = (protocolData) => {
  if (!protocolData) return false
  if (
    protocolData.roomTemperature > 0 &&
    protocolData.waterTemperature > 0 &&
    protocolData.therapyType.length > 0 &&
    protocolData.thermalComfort.length > 0 &&
    protocolData.userType.length > 0 &&
    protocolData.immersionType.length > 0
  )
    return true
  return false
}

export const canComputeProtocolAtom = atom((get) => {
  const protocolFormState = get(protocolFormStateAtom)
  return protocolDataIsComplete(protocolFormState)
})
