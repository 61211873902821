import PropTypes from "prop-types"
import { rangeArray } from "../../utils"

const VASRatingStars = ({
  rating,
  inputName,
  handleChange,
  disabled,
  bgClass = ""
}) => {
  const inputs = rangeArray(0, 10).map((star, index) => {
    const classNameVal =
      (index === 0 ? "rating-hidden" : "mask mask-star-2") +
      (bgClass.length ? " " + bgClass : "")
    return (
      <input
        key={index}
        type="radio"
        name={inputName}
        className={classNameVal}
        onChange={handleChange}
        value={star}
        checked={rating === star}
        disabled={disabled}
      />
    )
  })
  return <div className="rating rating-lg">{inputs}</div>
}

VASRatingStars.propTypes = {
  rating: PropTypes.number,
  inputName: PropTypes.string,
  handleChange: PropTypes.func,
  disabled: PropTypes.bool,
  bgClass: PropTypes.string
}

export default VASRatingStars
