import app from "../firebase/firebase"
import { getDoc, getFirestore, doc } from "firebase/firestore"

const db = getFirestore(app)

export const GetMedicalCenterData = async (medicalCenterUid) => {
  if (!medicalCenterUid || medicalCenterUid.length === 0) {
    throw new Error("medical center uid is required")
  }
  try {
    const q = doc(db, "medicalcenters", medicalCenterUid)
    const data = await getDoc(q)
    return data.data()
  } catch (e) {
    // TODO handle error handling
    console.error("error getting medical center data", e)
    return null
  }
}
