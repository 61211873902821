export class SessionLog {
  constructor(status, createdAt) {
    this.status = status
    this.createdAt = createdAt
  }
}

export const SessionLogConverter = {
  toFirestore: (log) => {
    return {
      status: log.status,
      createdAt: log.createdAt
    }
  },
  // This is unlikely to be used, have it for completeness
  fromFirestore: (snapshot, options) => {
    const data = snapshot.data(options)
    return SessionLogConverter.fromFirestoreObject(data)
  },
  fromFirestoreObject: (data) => {
    if (!data) {
      return null
    }
    return new SessionLog(data.status, data.createdAt)
  }
}
