import { atom } from "jotai"

export const FEEDBACK_FORM_INITIAL_STATE = {
  vas1Rating: 0,
  vas2Rating: 0,
  vas3Rating: 0,
  interruptedFeedback: "",
  notes: ""
}

export const feedbackFormStateAtom = atom({ ...FEEDBACK_FORM_INITIAL_STATE })

export const interruptedFeedbackBoxVisibleAtom = atom(false)

export const isSubmittingFeedbackAtom = atom(false)

export const canSubmitFeedbackAtom = atom((get) => {
  const feedbackFormState = get(feedbackFormStateAtom)
  const interruptedFeedbackBoxVisible = get(interruptedFeedbackBoxVisibleAtom)
  if (feedbackFormState.vas1Rating === 0) return false
  if (feedbackFormState.vas2Rating === 0) return false
  if (feedbackFormState.vas3Rating === 0) return false
  if (
    interruptedFeedbackBoxVisible &&
    feedbackFormState.interruptedFeedback.length === 0
  )
    return false
  return true
})
