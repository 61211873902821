import { useAtom } from "jotai"
import PropTypes from "prop-types"
import CollapseCard from "../../../components/CollapseCard"
import {
  acceptInputNumbersOnly,
  handleNumericStateChange,
  handleFloatOnBlurStateChange,
  handleTextStateChange,
  selectAllOnFocus
} from "../../../utils"
import {
  immersionTimeAtom,
  bodyImmersionTimeAtom,
  timerStateAtom
} from "../TimerSection/timer-ui-state"
import { sectionProtocolStateAtom } from "./../session-ui-state"
import {
  canComputeProtocolAtom,
  isSubmittingProtocolAtom,
  protocolFormStateAtom
} from "./protocol-ui-state"
import {
  THERAPY_TYPES,
  THERMAL_COMFORT_TYPES,
  USER_TYPES,
  IMMERSION_TYPES
} from "../../../constants"
import { convertSecondsToMinAndSecs } from "../TimerSection/protocol-calculator"
import { toast } from "react-toastify"
import { UpdateSession } from "../../../components/user/user"
import { CurrentSession } from "../../../models/session"
import { patientDataFormStateAtom } from "../PatientDataSection/patient-data-ui-state"
import { useState } from "react"

const ProtocolSection = ({ transitionToNextStage }) => {
  const [sectionState, setSectionState] = useAtom(sectionProtocolStateAtom)
  const [protocolFormState, setProtocolFormState] = useAtom(
    protocolFormStateAtom
  )

  const [canComputeProtocol] = useAtom(canComputeProtocolAtom)
  const [isSubmittingProtocol, setIsSubmittingProtocol] = useAtom(
    isSubmittingProtocolAtom
  )

  const [, setImmersionTime] = useAtom(immersionTimeAtom)
  const [, setBodyImmersionTime] = useAtom(bodyImmersionTimeAtom)

  const setTimerState = useAtom(timerStateAtom)[1]
  const [currentSession] = useAtom(CurrentSession)
  const [patientDataFormState] = useAtom(patientDataFormStateAtom)

  const [errorMessage, setErrorMessage] = useState("")

  const handleCollapsedChanged = (event) => {
    setSectionState({ ...sectionState, collapsed: event.target.checked })
  }

  if (sectionState.visible === false) {
    return <></>
  }

  const handleFloatOnBlur = (fieldName) => {
    handleFloatOnBlurStateChange(
      fieldName,
      protocolFormState,
      setProtocolFormState
    )
  }

  const handleNumericChange = (event) => {
    handleNumericStateChange(event, protocolFormState, setProtocolFormState)
  }

  const handleChange = (event) => {
    handleTextStateChange(event, protocolFormState, setProtocolFormState)
  }

  const handleProtocolDataServerResponse = (error) => {
    console.log("HANDLING PROTOCOL SERVER RESPONSE")
    setIsSubmittingProtocol(false)
    transitionToNextStage()

    if (error) {
      toast("Errore nel salvataggio dati protocollo", { type: "error" })
    } else {
      toast("Dati protocollo salvati", { type: "info" })
    }
  }

  const thermalComfortOptions = THERMAL_COMFORT_TYPES.map(
    ({ value, label }, index) => {
      return (
        <option key={index} value={value}>
          {label}
        </option>
      )
    }
  )

  const userTypeOptions = USER_TYPES.map(({ value, label }, index) => {
    return (
      <option key={index} value={value}>
        {label}
      </option>
    )
  })

  const immersionTypeOptions = IMMERSION_TYPES.map(
    ({ value, label }, index) => {
      return (
        <option key={index} value={value}>
          {label}
        </option>
      )
    }
  )

  const handleSubmit = async (event) => {
    event.preventDefault()
    if (canComputeProtocol) {
      setIsSubmittingProtocol(true)
      setErrorMessage("")

      const date = new Date()
      const age =
        date.getFullYear() -
        new Date(patientDataFormState.dateOfBirth).getFullYear()

      // TODO: update timer

      const protocolComputeOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          data: {
            age,
            percentFatMass: patientDataFormState.percentFatMass,
            waterTemp: protocolFormState.waterTemperature,
            therapyType: protocolFormState.therapyType,
            thermalComfort: protocolFormState.thermalComfort,
            userType: protocolFormState.userType,
            immersionType: protocolFormState.immersionType
          }
        })
      }

      const res = await fetch(
        process.env.REACT_APP_PROTOCOL_COMPUTE_URL,
        protocolComputeOptions
      )
      const resData = await res.json()
      console.log("protocol:", resData)
      if (!res.ok || resData.error) {
        setIsSubmittingProtocol(false)
        toast("Errore nel calcolo protocollo", { type: "error" })
        setErrorMessage(
          "Errore nel calcolo protocollo immersione, controllare la connessione internet o notificare QRYO."
        )
        console.log("Protocol compute error:", resData)
      } else {
        setIsSubmittingProtocol(false)
        setErrorMessage("")

        const computedImmersionTime = convertSecondsToMinAndSecs(
          resData.immersionTime
        )
        setImmersionTime(computedImmersionTime)
        setBodyImmersionTime({
          lowerBodyTime: resData.lower_body_time,
          upperBodyTime: resData.upper_body_time
        })

        const newTimerState = {
          minutesRemaining: computedImmersionTime.minutes,
          secondsRemaining: computedImmersionTime.seconds,
          state: "NOT_STARTED"
        }

        setTimerState(newTimerState)

        const error = await UpdateSession(currentSession.uuid, {
          protocolData: {
            age,
            ...protocolFormState,
            immersionProtocolTime: resData.immersionTime,
            bodyImmersionTimes: {
              lowerBodyTime: resData.lower_body_time,
              upperBodyTime: resData.upper_body_time
            }
          }
        })
        handleProtocolDataServerResponse(error)
      }
    }
  }

  const therapyTypeOptions = THERAPY_TYPES.map(({ value, label }, index) => {
    return (
      <option key={index} value={value}>
        {label}
      </option>
    )
  })

  console.log("PROTOCOL FORM STATE", protocolFormState)

  return (
    <CollapseCard
      isCollapsed={sectionState.collapsed}
      isCompleted={sectionState.completed}
      handleCollapsedChanged={handleCollapsedChanged}
      title={"Calcolo Protocollo QRYO"}
    >
      <form onSubmit={handleSubmit}>
        <div className="form-control">
          <label className="label">
            <span className="label-text">Temperatura Stanza (C)</span>
          </label>
          <input
            id="roomTemperature"
            name="roomTemperature"
            type="number"
            required
            placeholder="C"
            min={0}
            max={60}
            step={0.1}
            onChange={handleNumericChange}
            onBlur={handleFloatOnBlur}
            value={protocolFormState.roomTemperature}
            disabled={sectionState.completed}
            className="input input-bordered"
            onFocus={selectAllOnFocus}
            onKeyPress={acceptInputNumbersOnly}
          />
        </div>
        <div className="form-control">
          <label className="label">
            <span className="label-text">Temperatura Acqua Vasca (C)</span>
          </label>
          <input
            id="waterTemperature"
            name="waterTemperature"
            type="number"
            required
            placeholder="C"
            min={0}
            max={40}
            step={0.1}
            onChange={handleNumericChange}
            onBlur={handleFloatOnBlur}
            value={protocolFormState.waterTemperature}
            disabled={sectionState.completed}
            className="input input-bordered"
            onFocus={selectAllOnFocus}
            onKeyPress={acceptInputNumbersOnly}
          />
        </div>
        <div className="form-control">
          <label className="label">
            <span className="label-text">Comfort termico paziente</span>
          </label>
          <select
            id="thermalComfort"
            name="thermalComfort"
            type="select"
            required
            onChange={handleChange}
            className="select select-bordered"
            value={protocolFormState.thermalComfort}
            disabled={sectionState.completed}
          >
            <option disabled value="">
              Scegliere comfort termico
            </option>
            {thermalComfortOptions}
          </select>
        </div>
        <div className="form-control">
          <label className="label">
            <span className="label-text">Tipo di terapia</span>
          </label>
          <select
            id="therapyType"
            name="therapyType"
            type="select"
            required
            onChange={handleChange}
            className="select select-bordered"
            value={protocolFormState.therapyType}
            disabled={sectionState.completed}
          >
            <option disabled value="">
              Scegliere terapia
            </option>
            {therapyTypeOptions}
          </select>
        </div>
        <div className="form-control">
          <label className="label">
            <span className="label-text">Tipo di paziente</span>
          </label>
          <select
            id="userType"
            name="userType"
            type="select"
            required
            onChange={handleChange}
            className="select select-bordered"
            value={protocolFormState.userType}
            disabled={sectionState.completed}
          >
            <option disabled value="">
              Scegliere tipo paziente
            </option>
            {userTypeOptions}
          </select>
        </div>
        <div className="form-control">
          <label className="label">
            <span className="label-text">Tipo di immersione</span>
          </label>
          <select
            id="immersionType"
            name="immersionType"
            type="select"
            required
            onChange={handleChange}
            className="select select-bordered"
            value={protocolFormState.immersionType}
            disabled={sectionState.completed}
          >
            <option disabled value="">
              Scegliere tipologia immersione
            </option>
            {immersionTypeOptions}
          </select>
        </div>

        {errorMessage.length > 0 && (
          <p className="text-error mt-4">{errorMessage}</p>
        )}
        {!sectionState.completed && (
          <div className="flex mt-4 gap-2">
            <button
              type="submit"
              className={
                "btn btn-block flex-1" +
                (canComputeProtocol ? " btn-primary" : "") +
                (isSubmittingProtocol ? " loading" : "")
              }
              disabled={!canComputeProtocol}
            >
              {isSubmittingProtocol
                ? "Salvataggio Protocollo Immersione"
                : "Calcola Protocollo Immersione"}
            </button>
          </div>
        )}
      </form>
    </CollapseCard>
  )
}

ProtocolSection.propTypes = {
  transitionToNextStage: PropTypes.func,
  currentSession: PropTypes.object
}

export default ProtocolSection
