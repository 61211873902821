import { atom } from "jotai"
import { FeedbackConverter } from "./feedback"
import { PatientDataConverter } from "./patient-data"
import { ProtocolDataConverter } from "./protocol-data"
import { SessionLogConverter } from "./session-log"

export const CurrentSession = atom(null)

export class Session {
  constructor(
    uuid,
    doctor,
    medicalCenterUuid,
    createdAt,
    updatedAt,
    patientData = null,
    protocolData = null,
    sessionLog = [],
    feedback = []
  ) {
    this.uuid = uuid
    this.doctor = doctor
    this.medicalCenterUuid = medicalCenterUuid
    this.createdAt = createdAt
    this.updatedAt = updatedAt
    this.patientData = patientData
    this.protocolData = protocolData
    this.sessionLog = sessionLog
    this.feedback = feedback
  }
}

export const SessionConverter = {
  toFirestore: (session) => {
    return {
      doctor: session.doctor,
      medicalCenterUuid: session.medicalCenterUuid,
      createdAt: session.createdAt,
      updatedAt: session.updatedAt,
      patientData: PatientDataConverter.toFirestore(session.patientData),
      protocolData: ProtocolDataConverter.toFirestore(session.protocolData),
      sessionLog: session.sessionLog.map((log) =>
        SessionLogConverter.toFirestore(log)
      ),
      feedback: session.feedback.map((feedback) =>
        FeedbackConverter.toFirestore(feedback)
      )
    }
  },
  fromFirestore: (snapshot, options) => {
    const data = snapshot.data(options)
    return new Session(
      snapshot.id,
      data.doctor,
      data.medicalCenterUuid,
      data.createdAt,
      data.updatedAt,
      PatientDataConverter.fromFirestoreObject(data.patientData),
      ProtocolDataConverter.fromFirestoreObject(data.protocolData),
      data.sessionLog.map((log) =>
        SessionLogConverter.fromFirestoreObject(log)
      ),
      data.feedback.map((feedback) =>
        FeedbackConverter.fromFirestoreObject(feedback)
      )
    )
  }
}

export const NewSession = (doctor, medicalCenterId) =>
  new Session(
    null,
    doctor,
    medicalCenterId,
    Date.now(),
    Date.now(),
    null,
    null,
    [],
    []
  )
