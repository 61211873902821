import { useParams } from "react-router-dom"
import PublicSessionPageSections from "./PublicSessionPageSections"
import PublicSessionPageNoEmail from "./PublicSessionPageNoEmail"
import { validateEmail } from "../../utils"

export default function PublicSessionPage() {
  const { email } = useParams()

  const emailValid = validateEmail(email)

  return (
    <div className="h-screen flex">
      {/* Content area */}
      <div className="flex-1 flex flex-col overflow-hidden">
        {/* Main content */}
        <div className="h-full flex-1 flex items-stretch overflow-hidden">
          <main className="flex-1 overflow-y-auto bg-base-200">
            {emailValid ? (
              <PublicSessionPageSections email={email} />
            ) : (
              <PublicSessionPageNoEmail />
            )}
          </main>
        </div>
      </div>
    </div>
  )
}
