export class Feedback {
  constructor(type, value, createdAt) {
    this.type = type
    this.value = value
    this.createdAt = createdAt
  }
}

export const FeedbackConverter = {
  toFirestore: (feedback) => {
    return {
      type: feedback.type,
      value: feedback.value,
      createdAt: feedback.createdAt
    }
  },
  // This is unlikely to be used, have it for completeness
  fromFirestore: (snapshot, options) => {
    const data = snapshot.data(options)
    return FeedbackConverter.fromFirestoreObject(data)
  },
  fromFirestoreObject: (data) => {
    if (!data) {
      return null
    }
    return new Feedback(data.type, data.value, data.createdAt)
  }
}
