import { useAtom } from "jotai"
import PropTypes from "prop-types"
import { toast } from "react-toastify"
import CollapseCard from "../../../components/CollapseCard"
import { CurrentSession } from "../../../models/session"
import { sectionFeedbackStateAtom } from "../session-ui-state"
import {
  canSubmitFeedbackAtom,
  feedbackFormStateAtom,
  interruptedFeedbackBoxVisibleAtom,
  isSubmittingFeedbackAtom
} from "./feedback-section-ui-state"

import VASRatingStars from "../../common/VASRatingStars"

const FeedbackSection = ({ transitionToNextStage, asyncUpdateSession }) => {
  const [sectionState, setSectionState] = useAtom(sectionFeedbackStateAtom)
  const [feedbackFormState, setFeedbackFormState] = useAtom(
    feedbackFormStateAtom
  )

  const [interruptedFeedbackBoxVisible] = useAtom(
    interruptedFeedbackBoxVisibleAtom
  )

  const [canSubmitFeedback] = useAtom(canSubmitFeedbackAtom)
  const [isSubmittingFeedback, setIsSubmittingFeedback] = useAtom(
    isSubmittingFeedbackAtom
  )

  const [currentSession] = useAtom(CurrentSession)

  const handleCollapsedChanged = (event) => {
    setSectionState({ ...sectionState, collapsed: event.target.checked })
  }

  if (sectionState.visible === false) {
    return <></>
  }

  const handleChange = (event) => {
    const name = event.target.name
    const value = event.target.value
    const type = event.target.type
    if (name in feedbackFormState) {
      if (type === "radio") {
        feedbackFormState[name] = Number(value)
      } else {
        feedbackFormState[name] = value
      }
      setFeedbackFormState({ ...feedbackFormState })
    }
  }

  const handleFeedbackDataServerResponse = (error) => {
    console.log("HANDLING FEEDBACK SERVER RESPONSE")
    setIsSubmittingFeedback(false)
    transitionToNextStage()

    if (error) {
      toast("Errore nel salvataggio feedback", { type: "error" })
    } else {
      toast("Feedback salvato, sessione completata", { type: "info" })
    }
  }

  const handleSubmit = async (event) => {
    event.preventDefault()

    if (canSubmitFeedback) {
      setIsSubmittingFeedback(true)
      const feedback = Object.entries(feedbackFormState).map(
        ([key, value]) => ({ type: key, value, createdAt: Date.now() })
      )
      const error = await asyncUpdateSession(currentSession.uuid, { feedback })
      handleFeedbackDataServerResponse(error)
    }
  }

  return (
    <CollapseCard
      isCollapsed={sectionState.collapsed}
      isCompleted={sectionState.completed}
      handleCollapsedChanged={handleCollapsedChanged}
      title={"Feedback"}
    >
      <form onSubmit={handleSubmit}>
        <div className="form-control w-full max-w-xs">
          <label className="label">
            <span className="label-text">VAS 1</span>
          </label>

          <VASRatingStars
            rating={feedbackFormState.vas1Rating}
            inputName="vas1Rating"
            handleChange={handleChange}
            bgClass="bg-blue-600"
            disabled={sectionState.completed}
          />
        </div>

        <div className="form-control w-full max-w-xs">
          <label className="label">
            <span className="label-text">VAS 2</span>
          </label>

          <VASRatingStars
            rating={feedbackFormState.vas2Rating}
            inputName="vas2Rating"
            handleChange={handleChange}
            bgClass="bg-orange-600"
            disabled={sectionState.completed}
          />
        </div>

        <div className="form-control w-full max-w-xs">
          <label className="label">
            <span className="label-text">VAS 3</span>
          </label>

          <VASRatingStars
            rating={feedbackFormState.vas3Rating}
            inputName="vas3Rating"
            handleChange={handleChange}
            bgClass="bg-green-600"
            disabled={sectionState.completed}
          />
        </div>

        {interruptedFeedbackBoxVisible && (
          <div className="form-control w-full max-w-xs">
            <label className="label">
              <span className="label-text">
                Perché è stata interrotta la sessione?
              </span>
            </label>
            <textarea
              className="textarea textarea-bordered"
              placeholder="Ragione interruzione ad es. troppo freddo, dolore alle estremità, tachicardia, etc. "
              name="interruptedFeedback"
              onChange={handleChange}
              value={feedbackFormState.interruptedFeedback}
              disabled={sectionState.completed}
            ></textarea>
          </div>
        )}

        <div className="form-control w-full max-w-xs">
          <label className="label">
            <span className="label-text">Altre note e feedback</span>
          </label>
          <textarea
            className="textarea textarea-bordered"
            placeholder="Aggiungere altre note e/o feedback riguardo la sessione"
            name="notes"
            onChange={handleChange}
            value={feedbackFormState.notes}
            disabled={sectionState.completed}
          ></textarea>
        </div>

        {!sectionState.completed && (
          <button
            className={
              "mt-4 w-full btn" +
              (canSubmitFeedback ? " btn-primary" : "") +
              (isSubmittingFeedback ? " loading" : "")
            }
            disabled={!canSubmitFeedback}
          >
            {isSubmittingFeedback ? "Salvataggio dati" : "Completa Sessione"}
          </button>
        )}
      </form>
    </CollapseCard>
  )
}

FeedbackSection.propTypes = {
  transitionToNextStage: PropTypes.func,
  currentSession: PropTypes.object,
  asyncUpdateSession: PropTypes.func
}

export default FeedbackSection
