export const URL_PATH_SESSION = "/sessione"
export const URL_PATH_LOGIN = "/"
export const URL_PATH_PUBLIC_SESSION_VALID = "/protocollo-qryo/:email"
export const URL_PATH_PUBLIC_SESSION_NO_EMAIL = "/protocollo-qryo"

export const SKIN_TYPES = [
  "Tipo 1",
  "Tipo 2",
  "Tipo 3",
  "Tipo 4",
  "Tipo 5",
  "Tipo 6"
]

export const THERMAL_COMFORT_TYPES = [
  {
    value: "warm",
    label: "Corpo accaldato"
  },
  {
    value: "normal",
    label: "Corpo a temperatura normale"
  },
  {
    value: "cold",
    label: "Utente percepisce freddo"
  }
]

export const THERAPY_TYPES = [
  {
    value: "post_exercise_recovery",
    label: "Recupero post esercizio"
  },
  {
    value: "post_injury_recovery",
    label: "Recupero post infortunio"
  },
  {
    value: "contrast",
    label: "Contrast"
  }
]

export const USER_TYPES = [
  {
    value: "first_time",
    label: "Prima volta"
  },
  {
    value: "intermediate",
    label: "Intermedio"
  },
  {
    value: "expert",
    label: "Esperto"
  }
]

export const IMMERSION_TYPES = [
  {
    value: "lower_body",
    label: "Immersione solo gambe"
  },
  {
    value: "full_body",
    label: "Immersione total body (gambe e busto)"
  }
]
