import { useState } from "react"
import { Navigate } from "react-router-dom"
import { useAtom } from "jotai"
import {
  isLoggedInAtom,
  LoginWithEmailAndPassword
} from "../../components/auth/authentication"
import { URL_PATH_SESSION } from "../../constants"
import { validateEmail, validatePassword } from "../../utils"

const intialLoginFormState = {
  email: "",
  password: ""
}

function LoginPage() {
  const [loginFormState, setLoginFormState] = useState(intialLoginFormState)
  const [loginErrorMessage, setLoginErrorMessage] = useState("")

  const [isLoggedIn, setIsLoggedIn] = useAtom(isLoggedInAtom)
  if (isLoggedIn) {
    console.log("redirect due to login")
    return <Navigate to={URL_PATH_SESSION} replace={true} />
  }

  const handleChange = (event) => {
    const value = event.target.value
    const name = event.target.name

    if (name === "email") {
      setLoginFormState({ ...loginFormState, email: value })
    }
    if (name === "password") {
      setLoginFormState({ ...loginFormState, password: value })
    }
  }

  const login = async (event) => {
    event.preventDefault()

    const loginError = await LoginWithEmailAndPassword(
      loginFormState.email,
      loginFormState.password
    )
    if (!loginError) {
      setIsLoggedIn(true)
      setLoginErrorMessage("")
      setLoginFormState(intialLoginFormState)
    } else {
      console.log(loginError)
      setLoginErrorMessage(loginError)
    }
  }

  const canLogin =
    validatePassword(loginFormState.password) &&
    validateEmail(loginFormState.email)

  return (
    <div className="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <img
          className="mx-auto h-16 w-auto"
          src="/assets/qryo_logo_blue_alpha.png"
          alt="QRYO Logo - Performance Forever"
        />
        <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
          Accedi alla piattaforma QRYO
        </h2>
      </div>

      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
          <form className="space-y-6" onSubmit={login}>
            <div className="form-control w-full">
              <label className="label">
                <span className="label-text">Email</span>
              </label>
              <input
                id="email"
                name="email"
                type="email"
                autoComplete="email"
                required
                placeholder="esempio@email.com"
                value={loginFormState.email}
                onChange={handleChange}
                className="input input-bordered w-full"
              />
            </div>

            <div className="form-control w-full">
              <label className="label">
                <span className="label-text">Password</span>
              </label>
              <input
                id="password"
                name="password"
                type="password"
                autoComplete="current-password"
                required
                placeholder="********"
                value={loginFormState.password}
                onChange={handleChange}
                className="input input-bordered w-full"
              />
            </div>

            <div className="flex items-center justify-between">
              <div className="text-sm">
                <a
                  href="#"
                  className="font-medium text-indigo-600 hover:text-indigo-500"
                >
                  Dimenticato la password?
                </a>
              </div>
            </div>

            {loginErrorMessage.length > 0 && (
              <div className="flex items-center justify-between">
                <div className="text-sm text-error">{loginErrorMessage}</div>
              </div>
            )}

            <div>
              <button
                type="submit"
                className="btn btn-block btn-primary"
                disabled={!canLogin}
              >
                Accedi
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default LoginPage
