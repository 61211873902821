import {
  getDoc,
  getFirestore,
  doc,
  query,
  collection,
  where,
  getDocs,
  addDoc,
  updateDoc,
  arrayUnion,
  orderBy
} from "firebase/firestore"
import { NewSession, SessionConverter } from "../../models/session"
import {
  NewPublicSession,
  PublicSessionConverter
} from "../../models/public-session"
import app from "../firebase/firebase"

const db = getFirestore(app)

export const GetUserData = async (user) => {
  if (!user) {
    throw new Error("user is required")
  }
  try {
    const q = doc(db, "users", user.uid)
    const data = await getDoc(q)
    return data.data()
  } catch (e) {
    // TODO handle error handling
    console.error("error getting user data", e)
    return null
  }
}

export const GetAllSessionData = async (userId) => {
  if (!userId) {
    return
  }
  try {
    const q = query(
      collection(db, "sessions"),
      where("doctor", "==", userId),
      orderBy("createdAt")
    ).withConverter(SessionConverter)
    const docs = await getDocs(q)
    const sessions = []
    docs.forEach((session) => sessions.push(session.data()))
    return sessions
  } catch (e) {
    console.error("error getting session data", e)
    // TODO error handling
    return null
  }
}

export const CreateNewSession = async (userId, medicalId) => {
  if (!userId || !medicalId) {
    throw new Error("userId and medicalId are required")
  }
  try {
    const newSession = NewSession(userId, medicalId)
    const created = await addDoc(
      collection(db, "sessions"),
      SessionConverter.toFirestore(newSession)
    )
    console.log("created session", created)
    // TODO there has to be a better way of doing this
    const session = { uuid: created.id }
    return session
  } catch (e) {
    console.error("error creating new session", e)
    // TODO error handling
    return null
  }
}

export const UpdateSession = async (sessionId, changes = {}) => {
  try {
    console.log("updating session", sessionId)
    const sessionRef = doc(db, "sessions", sessionId)
    await updateDoc(sessionRef, changes)
  } catch (e) {
    console.error("error updating session", e)
    // TODO error handling
    return e
  }
}

export const AddSessionLog = async (sessionId, status) => {
  try {
    const error = await UpdateSession(sessionId, {
      sessionLog: arrayUnion({ status, createdAt: Date.now() })
    })
    return error
  } catch (e) {
    console.error("error adding session log", e)
    return e
  }
}

export const CreateNewPublicSession = async (email, protocolData) => {
  if (!email || !protocolData) {
    throw new Error("email and protocolData are required")
  }
  try {
    const newSession = NewPublicSession(email, protocolData)
    const created = await addDoc(
      collection(db, "public-sessions"),
      PublicSessionConverter.toFirestore(newSession)
    )
    // console.log("created public session", created)
    // console.log("created public session data=", created.data())
    const createdSession = await getDoc(doc(db, "public-sessions", created.id))

    const createdPublicSession =
      PublicSessionConverter.fromFirestore(createdSession)
    console.log("created public session=", createdPublicSession)
    return createdPublicSession
  } catch (e) {
    console.error("error creating new public session", e)
    // TODO error handling
    return null
  }
}

export const UpdatePublicSession = async (sessionId, changes = {}) => {
  try {
    console.log("updating public session", sessionId)
    const sessionRef = doc(db, "public-sessions", sessionId)
    await updateDoc(sessionRef, changes)
  } catch (e) {
    console.error("error updating public session", e)
    // TODO error handling
    return e
  }
}

export const AddPublicSessionLog = async (sessionId, status) => {
  try {
    const error = await UpdatePublicSession(sessionId, {
      sessionLog: arrayUnion({ status, createdAt: Date.now() })
    })
    return error
  } catch (e) {
    console.error("error adding session log", e)
    return e
  }
}
