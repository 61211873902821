import { atom } from "jotai"

export const PUBLIC_SESSION_STAGES = {
  stage_protocol: 0,
  stage_timer: 1,
  stage_feedback: 2,
  stage_completed: 3
}

export const PUBLIC_SESSION_STAGE_NAMES = {
  stage_protocol: "Protocollo",
  stage_timer: "Sessione",
  stage_feedback: "Feedback",
  stage_completed: "Completata"
}
export const currentPublicSessionStageAtom = atom("stage_protocol")

export const cancelPublicSessionModalVisibleAtom = atom(false)

export const canTransitionToPublicStage = (
  nextSessionStage,
  currentSessionStage
) => {
  if (nextSessionStage === PUBLIC_SESSION_STAGES.stage_timer) {
    // cannot transition to stage_protocol unless we are in stage_protocol
    if (currentSessionStage !== "stage_protocol") return false
  } else if (nextSessionStage === PUBLIC_SESSION_STAGES.stage_feedback) {
    // cannot transition to stage_protocol unless we are in stage_timer
    if (currentSessionStage !== "stage_timer") return false
  } else if (nextSessionStage === PUBLIC_SESSION_STAGES.stage_completed) {
    // cannot transition to stage_protocol unless we are in stage_timer
    if (currentSessionStage !== "stage_feedback") return false
  }
  return true
}

export const publicSectionProtocolStateAtom = atom({
  collapsed: false,
  completed: false,
  visible: true
})

export const publicSectionTimerStateAtom = atom({
  collapsed: true,
  completed: false,
  visible: false
})

export const publicSectionFeedbackStateAtom = atom({
  collapsed: true,
  completed: false,
  visible: false
})
