import { atom } from "jotai"

export const isFetchingSessionDataAtom = atom(true)

export const SESSION_STAGES = {
  stage_patient_data: 0,
  stage_protocol: 1,
  stage_timer: 2,
  stage_feedback: 3,
  stage_completed: 4
}

export const SESSION_STAGE_NAMES = {
  stage_patient_data: "Dati Paziente",
  stage_protocol: "Protocollo",
  stage_timer: "Sessione",
  stage_feedback: "Feedback",
  stage_completed: "Completata"
}

// NOTE use string for keys in SESSION_STAGES
// when setting currentSessionStageAtom
export const currentSessionStageAtom = atom("stage_patient_data")

export const cancelSessionModalVisibleAtom = atom(false)

export const canTransitionToStage = (nextSessionStage, currentSessionStage) => {
  if (nextSessionStage === SESSION_STAGES.stage_protocol) {
    // cannot transition to stage_protocol unless we are in stage_patient_data
    if (currentSessionStage !== "stage_patient_data") return false
  } else if (nextSessionStage === SESSION_STAGES.stage_timer) {
    // cannot transition to stage_protocol unless we are in stage_protocol
    if (currentSessionStage !== "stage_protocol") return false
  } else if (nextSessionStage === SESSION_STAGES.stage_feedback) {
    // cannot transition to stage_protocol unless we are in stage_timer
    if (currentSessionStage !== "stage_timer") return false
  } else if (nextSessionStage === SESSION_STAGES.stage_completed) {
    // cannot transition to stage_protocol unless we are in stage_timer
    if (currentSessionStage !== "stage_feedback") return false
  }
  return true
}

export const sectionPatientDataStateAtom = atom({
  collapsed: false,
  completed: false,
  visible: true
})

export const sectionProtocolStateAtom = atom({
  collapsed: true,
  completed: false,
  visible: false
})

export const sectionTimerStateAtom = atom({
  collapsed: true,
  completed: false,
  visible: false
})

export const sectionFeedbackStateAtom = atom({
  collapsed: true,
  completed: false,
  visible: false
})
