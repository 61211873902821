import { atom } from "jotai"
import { getAuth, signInWithEmailAndPassword, signOut } from "firebase/auth"
import { toast } from "react-toastify"
import app from "../firebase/firebase"

export const SESSION_KEY = "auth"

export const isLoggedInAtom = atom(false)
export const loggedInUserDataAtom = atom(null)
export const userMedicalCenterDataAtom = atom(null)

export const ErrorIncorrectPassword = "Email o password errati" // happens only if email is correct and password is incorrect
export const ErrorIncorrectEmail = "Email o password errati"
export const ErrorNetworkFailed =
  "Per favore controllare la connessione internet"
export const ErrorOtherError =
  "Si e verificato un'errore nell'accedere alla piattaforma. Per favore contattare lo staff di QRYO."

const auth = getAuth(app)

export const LoadAuthToken = () => {
  const token = localStorage.getItem(SESSION_KEY)
  return token
}

export const LoginWithEmailAndPassword = async (email, password) => {
  try {
    const creds = await signInWithEmailAndPassword(auth, email, password)
    if (!creds) {
      return "something went wrong"
    }
    localStorage.setItem(SESSION_KEY, creds.user.refreshToken)
    return null
  } catch (e) {
    return handleLoginError(e)
  }
}

export const Logout = () => {
  localStorage.removeItem(SESSION_KEY)
  signOut(auth)
}

const handleLoginError = (e) => {
  if (e.code === "auth/wrong-password") {
    toast.error(ErrorIncorrectPassword)
    return ErrorIncorrectPassword
  }
  if (e.code === "auth/user-not-found") {
    toast.error(ErrorIncorrectEmail)
    return ErrorIncorrectEmail
  }
  if (e.code === "auth/network-request-failed") {
    toast.error(ErrorNetworkFailed)
    return ErrorNetworkFailed
  }
  console.error("error logging in", e)
  return ErrorOtherError
}
