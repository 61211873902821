export class ProtocolData {
  constructor(
    age,
    roomTemperature,
    waterTemperature,
    immersionProtocolTime,
    userType,
    therapyType,
    thermalComfort,
    immersionType,
    bodyImmersionTimes
  ) {
    this.age = age
    this.roomTemperature = roomTemperature
    this.waterTemperature = waterTemperature
    this.immersionProtocolTime = immersionProtocolTime
    this.userType = userType
    this.therapyType = therapyType
    this.thermalComfort = thermalComfort
    this.immersionType = immersionType
    this.bodyImmersionTimes = bodyImmersionTimes
  }
}

export const ProtocolDataConverter = {
  toFirestore: (protocolData) => {
    if (!protocolData) return null
    return {
      age: protocolData.age,
      roomTemperature: protocolData.roomTemperature,
      waterTemperature: protocolData.waterTemperature,
      immersionProtocolTime: protocolData.immersionProtocolTime,
      userType: protocolData.userType,
      therapyType: protocolData.therapyType,
      thermalComfort: protocolData.thermalComfort,
      immersionType: protocolData.immersionType,
      bodyImmersionTimes: protocolData.bodyImmersionTimes
    }
  },
  // This is unlikely to be used, have it for completeness
  fromFirestore: (snapshot, options) => {
    const data = snapshot.data(options)
    return ProtocolDataConverter.fromFirestoreObject(data)
  },
  fromFirestoreObject: (data) => {
    if (!data) {
      return null
    }
    return new ProtocolData(
      data.age,
      data.roomTemperature,
      data.waterTemperature,
      data.immersionProtocolTime,
      data.userType,
      data.therapyType,
      data.thermalComfort,
      data.immersionType,
      data.bodyImmersionTimes
    )
  }
}
