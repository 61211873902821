import PropTypes from "prop-types"
import { useState } from "react"

const CancelSessionModal = ({
  modalVisible, // whether the modal is visible or not
  setModalVisible, // function to set the modal visibility
  onConfirmCancel, // function to call when the user confirms they want cancel
  asyncUpdateSession, // function that performs the session update on FireBase
  currentSessionUuid // the uuid of the current session
}) => {
  const [feedback, setFeedback] = useState("")
  const [errorMessage, setErrorMessage] = useState("")
  const [isSubmittingFeedback, setIsSubmittingFeedback] = useState(false)

  const canSubmit = feedback.length > 0

  const onCancel = () => {
    setModalVisible(false)
  }

  const onConfirm = async () => {
    if (!canSubmit) return
    if (isSubmittingFeedback) return
    setIsSubmittingFeedback(true)
    const error = await asyncUpdateSession(currentSessionUuid, {
      feedback: [
        {
          type: "cancel_reason",
          value: feedback,
          createdAt: Date.now()
        }
      ]
    })
    setIsSubmittingFeedback(false)
    if (!error) {
      onConfirmCancel()
      setModalVisible(false)
      setFeedback("")
      setErrorMessage("")
    } else {
      setErrorMessage(
        "Si è verificato un'errore, per favore controllare la connessione internet."
      )
    }
  }

  return (
    <>
      <input
        type="checkbox"
        id="cancel-session-modal"
        className="modal-toggle"
        checked={modalVisible}
        readOnly={true}
      />
      <div className="modal">
        <div className="modal-box">
          <h3 className="font-bold text-lg">Termina Sessione QRYO</h3>

          <p>Quale è la ragione per l&apos;annullamento della sessione?</p>
          <div className="form-control w-full max-w-xs">
            <textarea
              type="textarea"
              name="feedback"
              value={feedback}
              placeholder="Ragioni termine sessione"
              onChange={(e) => setFeedback(e.target.value)}
              className="textarea textarea-bordered"
              disabled={isSubmittingFeedback}
            />
          </div>
          {errorMessage.length > 0 && (
            <p className="text-error">{errorMessage}</p>
          )}
          <div className="modal-action">
            <button
              htmlFor="cancel-session-modal"
              className="btn btn-error"
              onClick={() => onCancel()}
              disabled={isSubmittingFeedback}
            >
              Annulla
            </button>
            <button
              htmlFor="cancel-session-modal"
              className={
                "btn " +
                (isSubmittingFeedback
                  ? "btn-success loading"
                  : canSubmit
                  ? "btn-primary"
                  : "btn btn-disabled")
              }
              onClick={() => onConfirm()}
            >
              {isSubmittingFeedback ? "Salvataggio dati" : "Conferma"}
            </button>
          </div>
        </div>
      </div>
    </>
  )
}

CancelSessionModal.propTypes = {
  modalVisible: PropTypes.bool,
  setModalVisible: PropTypes.func,
  onConfirmCancel: PropTypes.func,
  asyncUpdateSession: PropTypes.func,
  currentSessionUuid: PropTypes.string
}

export default CancelSessionModal
