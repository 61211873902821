import { atom } from "jotai"
import { FeedbackConverter } from "./feedback"
import { PublicProtocolDataConverter } from "./public-protocol-data"
import { SessionLogConverter } from "./session-log"

export const CurrentPublicSession = atom(null)

export class PublicSession {
  constructor(
    uuid,
    email,
    createdAt,
    updatedAt,
    protocolData = null,
    sessionLog = [],
    feedback = []
  ) {
    this.uuid = uuid
    this.email = email
    this.createdAt = createdAt
    this.updatedAt = updatedAt
    this.protocolData = protocolData
    this.sessionLog = sessionLog
    this.feedback = feedback
  }
}

export const PublicSessionConverter = {
  toFirestore: (session) => {
    return {
      email: session.email,
      createdAt: session.createdAt,
      updatedAt: session.updatedAt,
      protocolData: PublicProtocolDataConverter.toFirestore(
        session.protocolData
      ),
      sessionLog: session.sessionLog.map((log) =>
        SessionLogConverter.toFirestore(log)
      ),
      feedback: session.feedback.map((feedback) =>
        FeedbackConverter.toFirestore(feedback)
      )
    }
  },
  fromFirestore: (snapshot, options) => {
    const data = snapshot.data(options)
    return new PublicSession(
      snapshot.id,
      data.email,
      data.createdAt,
      data.updatedAt,
      PublicProtocolDataConverter.fromFirestoreObject(data.protocolData),
      data.sessionLog.map((log) =>
        SessionLogConverter.fromFirestoreObject(log)
      ),
      data.feedback.map((feedback) =>
        FeedbackConverter.fromFirestoreObject(feedback)
      )
    )
  }
}

export const NewPublicSession = (email, protocolData) =>
  new PublicSession(null, email, Date.now(), Date.now(), protocolData, [], [])
