import PropTypes from "prop-types"

import { CheckCircleIcon } from "@heroicons/react/24/solid"

const CollapseCard = ({
  children,
  title,
  isCollapsed,
  isCompleted,
  handleCollapsedChanged
}) => {
  const divClassName =
    "collapse collapse-arrow border border-base-300 bg-base-100" +
    (isCollapsed ? " collapse-close" : " collapse-open")

  return (
    <section
      aria-labelledby="primary-heading"
      className="min-w-0 flex-1 flex flex-col lg:order-last"
    >
      <div tabIndex={0} className={divClassName}>
        <input
          type="checkbox"
          className="peer"
          onChange={handleCollapsedChanged}
        />
        <div className="collapse-title text-xl font-medium flex flex-row items-center">
          {isCompleted && <CheckCircleIcon className="w-8 text-success" />}
          <p>{title}</p>
        </div>
        <div className="collapse-content">{children}</div>
      </div>
    </section>
  )
}

CollapseCard.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element.isRequired
  ]),
  title: PropTypes.string.isRequired,
  isCollapsed: PropTypes.bool,
  isCompleted: PropTypes.bool,
  handleCollapsedChanged: PropTypes.func
}

export default CollapseCard
