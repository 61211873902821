export class PatientData {
  constructor(
    name,
    dateOfBirth,
    weight,
    height,
    gender,
    percentFatMass,
    percentLeanMass
    // skinType,
  ) {
    this.name = name
    this.dateOfBirth = dateOfBirth
    this.weight = weight
    this.height = height
    this.gender = gender
    this.percentFatMass = percentFatMass
    this.percentLeanMass = percentLeanMass
    // this.skinType = skinType
  }
}

export const PatientDataConverter = {
  toFirestore: (patientData) => {
    if (!patientData) return null
    return {
      name: patientData.name,
      dateOfBirth: patientData.dateOfBirth,
      weight: patientData.weight,
      height: patientData.height,
      gender: patientData.gender,
      percentFatMass: patientData.percentFatMass,
      percentLeanMass: patientData.percentLeanMass
      // skinType: patientData.skinType,
    }
  },
  // This is unlikely to be used, have it for completeness
  fromFirestore: (snapshot, options) => {
    const data = snapshot.data(options)
    return PatientDataConverter.fromFirestoreObject(data)
  },
  fromFirestoreObject: (data) => {
    if (!data) {
      return null
    }
    return new PatientData(
      data.name,
      data.dateOfBirth,
      data.weight,
      data.height,
      data.gender,
      data.percentFatMass,
      data.percentLeanMass
      // data.skinType,
    )
  }
}
