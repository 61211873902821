import PropTypes from "prop-types"
import { useAtom } from "jotai"

import { patientDataFormResetModalVisibleAtom } from "./patient-data-ui-state"

const ConfirmPatientDataFormResetModal = ({ onConfirmReset }) => {
  const [formResetModalVisible, setFormResetModalVisible] = useAtom(
    patientDataFormResetModalVisibleAtom
  )

  const onCancel = () => {
    setFormResetModalVisible(false)
  }

  const onConfirm = () => {
    onConfirmReset()
    setFormResetModalVisible(false)
  }

  return (
    <>
      <input
        type="checkbox"
        id="confirm-form-reset-modal"
        className="modal-toggle"
        checked={formResetModalVisible}
        readOnly={true}
      />
      <div className="modal">
        <div className="modal-box">
          <h3 className="font-bold text-lg">
            Conferma la cancellazione dei dati
          </h3>
          <p className="py-4">
            Per favore confermare la cancellazione dei dati nel modulo.
          </p>
          <div className="modal-action">
            <label
              htmlFor="confirm-form-reset-modal"
              className="btn btn-error"
              onClick={() => onCancel()}
            >
              Annulla
            </label>
            <label
              htmlFor="confirm-form-reset-modal"
              className="btn btn-primary"
              onClick={() => onConfirm()}
            >
              Conferma
            </label>
          </div>
        </div>
      </div>
    </>
  )
}

ConfirmPatientDataFormResetModal.propTypes = {
  onConfirmReset: PropTypes.func,
  currentSession: PropTypes.object
}

export default ConfirmPatientDataFormResetModal
