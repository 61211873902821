import PropTypes from "prop-types"
import CancelSessionModal from "../common/CancelSessionModal"
import {
  cancelPublicSessionModalVisibleAtom,
  currentPublicSessionStageAtom,
  publicSectionProtocolStateAtom,
  publicSectionTimerStateAtom,
  publicSectionFeedbackStateAtom,
  PUBLIC_SESSION_STAGES,
  PUBLIC_SESSION_STAGE_NAMES
} from "./public-session-ui-state"
import { useAtom } from "jotai"
import SessionStepIndicator from "../common/SessionStepIndicator"
import PublicProtocolSection from "./PublicProtocolSection/PublicProtocolSection"
import { CurrentPublicSession } from "../../models/public-session"
import { UpdatePublicSession } from "../../components/user/user"
import { toast } from "react-toastify"
import {
  PUBLIC_PROTOCOL_FORM_INITIAL_STATE,
  publicProtocolFormStateAtom
} from "./PublicProtocolSection/public-protocol-ui-state"
import {
  TIMER_INITIAL_STATE,
  publicTimerStateAtom
} from "./PublicTimerSection/public-timer-ui-state"
import PublicTimerSection from "./PublicTimerSection/PublicTimerSection"
import PublicFeedbackSection from "./PublicFeedbackSection/PublicFeedbackSection"
import SessionCompletedWidget from "../common/SessionCompletedWidget"
import { useEffect, useRef } from "react"

function PublicSessionPageSections({ email }) {
  const [currentPublicSessionStage, setCurrentPublicSessionStage] = useAtom(
    currentPublicSessionStageAtom
  )

  const [cancelPublicSessionModalVisible, setCancelPublicSessionModalVisible] =
    useAtom(cancelPublicSessionModalVisibleAtom)

  const [publicSectionProtocolState, setPublicSectionProtocolState] = useAtom(
    publicSectionProtocolStateAtom
  )
  const [, setPublicProtocolFormState] = useAtom(publicProtocolFormStateAtom)

  const [publicSectionTimerState, setPublicSectionTimerState] = useAtom(
    publicSectionTimerStateAtom
  )
  const [, setPublicTimerState] = useAtom(publicTimerStateAtom)

  const [publicSectionFeedbackState, setPublicSectionFeedbackState] = useAtom(
    publicSectionFeedbackStateAtom
  )

  const timerRef = useRef(null)

  const stopInterval = () => {
    if (timerRef.current) {
      clearInterval(timerRef.current)
    }
  }

  // needed ?
  const [currentPublicSession, setCurrentPublicSession] =
    useAtom(CurrentPublicSession)

  const createNewSession = () => {
    resetSession()
  }

  const cancelSession = () => {
    toast("Sessione annullata", { type: "success" })
    stopInterval()
    resetSession()
  }

  const resetSession = () => {
    setCurrentPublicSession(null)

    setCurrentPublicSessionStage("stage_protocol")

    // protocol reset
    setPublicSectionProtocolState({
      collapsed: false,
      completed: false,
      visible: true
    })

    setPublicProtocolFormState({ ...PUBLIC_PROTOCOL_FORM_INITIAL_STATE })

    // timer state
    setPublicSectionTimerState({
      collapsed: true,
      completed: false,
      visible: false
    })
    setPublicTimerState({ ...TIMER_INITIAL_STATE })

    // feedback state
    setPublicSectionFeedbackState({
      collapsed: true,
      completed: false,
      visible: false
    })
  }

  const transitionToNextStage = () => {
    if (currentPublicSessionStage === "stage_protocol") {
      setCurrentPublicSessionStage("stage_timer")
      setPublicSectionProtocolState({
        ...publicSectionProtocolState,
        completed: true,
        collapsed: true
      })
      setPublicSectionTimerState({
        ...publicSectionTimerState,
        visible: true,
        collapsed: false
      })
    }
    if (currentPublicSessionStage === "stage_timer") {
      setCurrentPublicSessionStage("stage_feedback")
      setPublicSectionTimerState({
        ...publicSectionTimerState,
        completed: true,
        collapsed: true
      })
      setPublicSectionFeedbackState({
        ...publicSectionFeedbackState,
        visible: true,
        collapsed: false
      })
    }
    if (currentPublicSessionStage === "stage_feedback") {
      // resetSession()
      setCurrentPublicSessionStage("stage_completed")
      setPublicSectionFeedbackState({
        ...publicSectionFeedbackState,
        visible: true,
        collapsed: true,
        completed: true
      })
    }
  }

  useEffect(() => {
    const unloadCallback = (event) => {
      event.preventDefault()
      event.returnValue = ""
      return ""
    }

    window.addEventListener("beforeunload", unloadCallback)
    return () => window.removeEventListener("beforeunload", unloadCallback)
  }, [])

  const showCancelButton =
    currentPublicSessionStage !== "stage_completed" &&
    currentPublicSessionStage !== "stage_protocol"

  return (
    <>
      <CancelSessionModal
        modalVisible={cancelPublicSessionModalVisible}
        setModalVisible={setCancelPublicSessionModalVisible}
        onConfirmCancel={cancelSession}
        asyncUpdateSession={UpdatePublicSession}
        currentSessionUuid={
          currentPublicSession ? currentPublicSession.uuid : ""
        }
      />
      {currentPublicSessionStage === "stage_completed" && (
        <SessionCompletedWidget createNewSession={createNewSession} />
      )}
      {showCancelButton && (
        <button
          className="btn btn-block btn-error"
          onClick={() => setCancelPublicSessionModalVisible(true)}
        >
          Termina Sessione
        </button>
      )}
      <SessionStepIndicator
        currentSessionStage={currentPublicSessionStage}
        sessionStages={PUBLIC_SESSION_STAGES}
        sessionStageNames={PUBLIC_SESSION_STAGE_NAMES}
      />
      <PublicProtocolSection
        email={email}
        transitionToNextStage={transitionToNextStage}
      />
      <PublicTimerSection
        transitionToNextStage={transitionToNextStage}
        timerRef={timerRef}
        stopInterval={stopInterval}
      />
      <PublicFeedbackSection
        transitionToNextStage={transitionToNextStage}
        asyncUpdateSession={UpdatePublicSession}
      />
    </>
  )
}

PublicSessionPageSections.propTypes = {
  email: PropTypes.string
}

export default PublicSessionPageSections
