export const acceptInputNumbersOnly = (event) => {
  if (!/[0-9.,]/.test(event.key)) {
    event.preventDefault()
  }
}

// generate an array in the range of x...y (both inclusive)
export const rangeArray = (x, y) => (x > y ? [] : [x, ...rangeArray(x + 1, y)])

export const handleNumericStateChange = (
  event,
  state,
  setState,
  defaultIfNull = ""
) => {
  const name = event.target.name
  const value = event.target.value
  if (name in state) {
    if (value.match(/^([0-9]{1,})?(\.)?([0-9]{1,})?$/)) {
      state[name] = parseFloat(value) || defaultIfNull
    }
    setState({ ...state })
  }
}

export const handleFloatOnBlurStateChange = (fieldName, state, setState) => {
  if (fieldName in state) {
    const fieldValue = state[fieldName]
    const parsedValue = parseFloat(fieldValue) || null
    setState({
      ...state,
      fieldName: parsedValue
    })
  }
}

export const handleTextStateChange = (event, state, setState) => {
  const name = event.target.name
  const value = event.target.value
  if (name in state) {
    state[name] = value
  }
  setState({ ...state })
}

export const selectAllOnFocus = (event) => {
  event.target.select()
}

export const isSessionCompleted = (session) =>
  session.feedback != null && session.feedback.length > 0

export const formatDate = (date) => {
  const pad2 = (n) => n.toString().padStart(2, "0")

  const map = {
    YYYY: date.getFullYear(),
    MM: pad2(date.getMonth() + 1),
    DD: pad2(date.getDate())
  }

  return `${map.YYYY}-${map.MM}-${map.DD}T00:00:00.000Z`
}

export const validateEmail = (email) => {
  const regex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return regex.test(email)
}

export const validatePassword = (password) => {
  return password.length >= 8
}
