import PropTypes from "prop-types"
import PatientDataSection from "./PatientDataSection/PatientDataSection"
import ProtocolSection from "./ProtocolSection/ProtocolSection"
import TimerSection from "./TimerSection/TimerSection"
import FeedbackSection from "./FeedbackSection/FeedbackSection"
import { useAtom } from "jotai"
import {
  currentSessionStageAtom,
  sectionPatientDataStateAtom,
  sectionProtocolStateAtom,
  sectionTimerStateAtom,
  sectionFeedbackStateAtom,
  cancelSessionModalVisibleAtom,
  SESSION_STAGES,
  SESSION_STAGE_NAMES
} from "./session-ui-state"

import SessionCompletedWidget from "../common/SessionCompletedWidget"
import CancelSessionModal from "./../common/CancelSessionModal"
import { UpdateSession } from "../../components/user/user"
import { CurrentSession } from "../../models/session"
import SessionStepIndicator from "../common/SessionStepIndicator"

const SessionPageSections = ({
  createNewSession,
  cancelSession,
  timerRef,
  stopInterval
}) => {
  const [currentSessionStage, setCurrentSessionStage] = useAtom(
    currentSessionStageAtom
  )

  const [sectionPatientData, setSectionPatientDataState] = useAtom(
    sectionPatientDataStateAtom
  )

  const [sectionProtocolState, setSectionProtocolState] = useAtom(
    sectionProtocolStateAtom
  )

  const [sectionTimerState, setSectionTimerState] = useAtom(
    sectionTimerStateAtom
  )

  const [sectionFeedbackState, setSectionFeedbackState] = useAtom(
    sectionFeedbackStateAtom
  )

  const [currentSession] = useAtom(CurrentSession)

  const transitionToNextStage = () => {
    if (currentSessionStage === "stage_patient_data") {
      setCurrentSessionStage("stage_protocol")

      setSectionPatientDataState({
        ...sectionPatientData,
        completed: true,
        collapsed: true
      })
      setSectionProtocolState({
        ...sectionProtocolState,
        visible: true,
        collapsed: false
      })
    }
    if (currentSessionStage === "stage_protocol") {
      setCurrentSessionStage("stage_timer")

      setSectionProtocolState({
        ...sectionProtocolState,
        completed: true,
        collapsed: true
      })
      setSectionTimerState({
        ...sectionTimerState,
        visible: true,
        collapsed: false
      })
    }
    if (currentSessionStage === "stage_timer") {
      setCurrentSessionStage("stage_feedback")
      setSectionTimerState({
        ...sectionTimerState,
        completed: true,
        collapsed: true
      })
      setSectionFeedbackState({
        ...sectionFeedbackState,
        visible: true,
        collapsed: false
      })
    }
    if (currentSessionStage === "stage_feedback") {
      // resetSession()
      setCurrentSessionStage("stage_completed")
      setSectionFeedbackState({
        ...sectionFeedbackState,
        visible: true,
        collapsed: true,
        completed: true
      })
    }
  }

  const [cancelSessionModalVisible, setCancelSessionModalVisible] = useAtom(
    cancelSessionModalVisibleAtom
  )

  const showCancelButton =
    currentSessionStage !== "stage_completed" &&
    currentSessionStage !== "stage_patient_data"

  return (
    <>
      {/* Primary column */}
      <CancelSessionModal
        modalVisible={cancelSessionModalVisible}
        setModalVisible={setCancelSessionModalVisible}
        onConfirmCancel={cancelSession}
        asyncUpdateSession={UpdateSession}
        currentSessionUuid={currentSession.uuid}
      />
      {currentSessionStage === "stage_completed" && (
        <SessionCompletedWidget createNewSession={createNewSession} />
      )}
      {showCancelButton && (
        <button
          className="btn btn-block btn-error"
          onClick={() => setCancelSessionModalVisible(true)}
        >
          Termina Sessione
        </button>
      )}
      <SessionStepIndicator
        currentSessionStageName={currentSessionStage}
        sessionStages={SESSION_STAGES}
        sessionStageNames={SESSION_STAGE_NAMES}
      />
      <PatientDataSection transitionToNextStage={transitionToNextStage} />
      <ProtocolSection transitionToNextStage={transitionToNextStage} />
      <TimerSection
        transitionToNextStage={transitionToNextStage}
        timerRef={timerRef}
        stopInterval={stopInterval}
      />
      <FeedbackSection
        transitionToNextStage={transitionToNextStage}
        asyncUpdateSession={UpdateSession}
      />
    </>
  )
}

SessionPageSections.propTypes = {
  createNewSession: PropTypes.func,
  cancelSession: PropTypes.func,
  timerRef: PropTypes.object,
  stopInterval: PropTypes.func
}

export default SessionPageSections
