import "react-toastify/dist/ReactToastify.min.css"
import "react-datepicker/dist/react-datepicker.css"

import { ToastContainer } from "react-toastify"
import { Routes, Route } from "react-router-dom"
import LoginPage from "./pages/login/LoginPage"
import SessionPage from "./pages/session/SessionPage"
import {
  URL_PATH_LOGIN,
  URL_PATH_PUBLIC_SESSION_NO_EMAIL,
  URL_PATH_PUBLIC_SESSION_VALID,
  URL_PATH_SESSION
} from "./constants"
import {
  isLoggedInAtom,
  LoadAuthToken,
  loggedInUserDataAtom,
  userMedicalCenterDataAtom
} from "./components/auth/authentication"
import { useAtom } from "jotai"
import { useEffect } from "react"
import { GetUserData } from "./components/user/user"
import { useAuthState } from "react-firebase-hooks/auth"
import app from "./components/firebase/firebase"
import { getAuth } from "firebase/auth"
import { GetMedicalCenterData } from "./components/medicalcenter/medicalcenter"

import { registerLocale, setDefaultLocale } from "react-datepicker"
import it from "date-fns/locale/it"
import PublicSessionPage from "./pages/public-session/PublicSessionPage"
registerLocale("it", it)
setDefaultLocale("it")

const fbAuth = getAuth(app)

function App() {
  const [auth] = useAuthState(fbAuth)
  const [isLoggedIn, setIsLoggedIn] = useAtom(isLoggedInAtom)
  const [, setUserData] = useAtom(loggedInUserDataAtom)
  const [, setUserMedicalCenterData] = useAtom(userMedicalCenterDataAtom)

  if (LoadAuthToken()) {
    setIsLoggedIn(true)
  }

  useEffect(() => {
    if (!isLoggedIn || !auth) {
      return
    }
    const fetchUserData = async () => {
      const userData = await GetUserData(auth)
      if (userData && userData.medicalCenterUuid) {
        const medicalCenterData = await GetMedicalCenterData(
          userData.medicalCenterUuid
        )
        setUserMedicalCenterData(medicalCenterData)
      }
      setUserData(userData)
    }
    fetchUserData()
  }, [isLoggedIn, auth])

  return (
    <>
      <Routes>
        <Route path={URL_PATH_LOGIN} element={<LoginPage />} />
        <Route path={URL_PATH_SESSION} element={<SessionPage />} />
        <Route
          path={URL_PATH_PUBLIC_SESSION_VALID}
          element={<PublicSessionPage />}
        />
        <Route
          path={URL_PATH_PUBLIC_SESSION_NO_EMAIL}
          element={<PublicSessionPage />}
        />
      </Routes>

      <ToastContainer
        position="bottom-right"
        theme="light"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  )
}

export default App
