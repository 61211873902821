import { useAtom } from "jotai"
import PropTypes from "prop-types"
import { useEffect } from "react"
import { zeroPad } from "react-countdown"
import { toast } from "react-toastify"

import CollapseCard from "../../../components/CollapseCard"
import { AddSessionLog } from "../../../components/user/user"
import { CurrentSession } from "../../../models/session"
import { sectionTimerStateAtom } from "../session-ui-state"
import {
  immersionTimeAtom,
  bodyImmersionTimeAtom,
  timerStateAtom
} from "./timer-ui-state"

const TimerSection = ({ transitionToNextStage, timerRef, stopInterval }) => {
  // TODO: stop interval in case min and secs are both < 0
  // TODO: on page load, compute seconds remaining based on UTC time from started (ie. save a deadline)

  const [sectionState, setSectionState] = useAtom(sectionTimerStateAtom)
  const bodyImmersionTime = useAtom(bodyImmersionTimeAtom)[0]
  const [timerState, setTimerState] = useAtom(timerStateAtom)
  const [immersionTime] = useAtom(immersionTimeAtom)

  // compute here the total seconds remaining
  const totalSecondsRemaining =
    timerState.secondsRemaining + 60 * timerState.minutesRemaining
  const totalMinusUpper =
    totalSecondsRemaining - bodyImmersionTime.upperBodyTime
  const lowerBodySecondsRemaining = totalMinusUpper > 0 ? totalMinusUpper : 0

  const hasUpperBodyImmersion = bodyImmersionTime.upperBodyTime > 0
  const shouldHaveUpperBodyImmersed =
    hasUpperBodyImmersion &&
    totalSecondsRemaining <= bodyImmersionTime.upperBodyTime

  // store a ref to the interval for the timer

  const handleCollapsedChanged = (event) => {
    setSectionState({ ...sectionState, collapsed: event.target.checked })
  }

  const [currentSession] = useAtom(CurrentSession)

  useEffect(() => {}, [])

  const startInterval = () => {
    // remove an interval if already present
    stopInterval()

    // don't restart if the session is already completed
    if (timerState.state === "COMPLETED") return

    // don't restart and mark as completed if secondsRemaning / minutesRemaining are <= 0
    if (timerState.secondsRemaining <= 0 && timerState.minutesRemaining <= 0) {
      setTimerState({ ...timerState, state: "COMPLETED" })
      return
    }

    timerRef.current = setInterval(() => {
      timerState.secondsRemaining -= 1
      timerState.state = "STARTED" // always set "STARTED" because interval is running
      if (timerState.secondsRemaining === 0) {
        if (timerState.minutesRemaining === 0) {
          stopInterval()
          timerState.state = "COMPLETED"
          sendSessionLogToServer("COMPLETED")
        }
      } else if (timerState.secondsRemaining < 0) {
        timerState.minutesRemaining -= 1
        timerState.secondsRemaining = 59
      }
      setTimerState({ ...timerState })
    }, 1000)
  }

  const onStartClicked = () => {
    // if timer is completed, return
    if (timerState.state === "COMPLETED") return
    setTimerState({ ...timerState, state: "STARTED" })
    sendSessionLogToServer("STARTED")
    startInterval()
  }

  const onPauseClicked = () => {
    if (timerState.state !== "STARTED") return
    stopInterval()
    setTimerState({ ...timerState, state: "PAUSED" })
    sendSessionLogToServer("PAUSED")
  }

  const handleSessionLogServerResponse = (status, error) => {
    if (status === "COMPLETED") {
      transitionToNextStage()
    }

    if (error) {
      toast("Errore nel salvataggio dati sessione", { type: "error" })
    } else {
      if (status === "STARTED") {
        toast("Sessione ripresa", { type: "info" })
      }
      if (status === "PAUSED") {
        toast("Sessione in pausa", { type: "info" })
      }
      if (status === "COMPLETED") {
        toast("Sessione completata", { type: "info" })
      }
    }
  }

  const sendSessionLogToServer = async (status) => {
    const error = await AddSessionLog(currentSession.uuid, status)
    handleSessionLogServerResponse(status, error)
  }

  // UI RENDERING OPTIONS / SECTION
  if (sectionState.visible === false) {
    return <></>
  }

  const showStartButton =
    timerState.state === "NOT_STARTED" || timerState.state === "PAUSED"

  const showPauseButton = timerState.state === "STARTED"

  let badgeText = "Nuova Sessione"
  let badgeColorClass = "badge-info"
  if (timerState.state === "STARTED") {
    badgeText = "Sessione Avviata"
    badgeColorClass = "badge-warning"
  } else if (timerState.state === "PAUSED") {
    badgeText = "Sessione in Pausa"
    badgeColorClass = "badge-error"
  } else if (timerState.state !== "NOT_STARTED") {
    badgeText = "Sessione Completata"
    badgeColorClass = "badge-success"
  }

  const countdownRenderer = ({ minutes, seconds }) => {
    const countDownText = `${zeroPad(minutes)}:${zeroPad(seconds)}`
    return (
      <div className="mr-auto ml-auto">
        <div className="stat flex-1">
          <div className="stat-title text-primary">Tempo Rimanente</div>
          <div className="stat-value text-primary text-center mt-4">
            {countDownText}
          </div>
        </div>
      </div>
    )
  }

  const fullBodyCSSClasses =
    totalSecondsRemaining <= 0
      ? " text-green-700"
      : shouldHaveUpperBodyImmersed && totalSecondsRemaining > 0
      ? " text-yellow-600 font-bold"
      : " text-gray-800"

  return (
    <CollapseCard
      isCollapsed={sectionState.collapsed}
      isCompleted={sectionState.completed}
      handleCollapsedChanged={handleCollapsedChanged}
      title={"Timer Protocollo QRYO"}
    >
      <div className="indicator m-3">
        <span
          className={
            "indicator-item indicator-center badge w-48 " + badgeColorClass
          }
        >
          {badgeText}
        </span>
        <div
          className="card card-bordered bg-base-100 shadow-xl"
          style={{ width: "20rem" }}
        >
          <div className="card-body">
            <h2 className="card-title">
              {countdownRenderer({
                minutes: timerState.minutesRemaining,
                seconds: timerState.secondsRemaining
              })}
            </h2>
            <div
              className={
                "flex justify-center" +
                (lowerBodySecondsRemaining > 0
                  ? " text-yellow-600 font-bold"
                  : " text-green-700")
              }
            >
              Gambe (
              {lowerBodySecondsRemaining > 0
                ? lowerBodySecondsRemaining + "s"
                : "completato"}
              )
            </div>
            {hasUpperBodyImmersion && (
              <div className={"flex justify-center" + fullBodyCSSClasses}>
                Gambe e busto (
                {totalSecondsRemaining > bodyImmersionTime.upperBodyTime
                  ? bodyImmersionTime.upperBodyTime + "s"
                  : totalSecondsRemaining > 0
                  ? totalSecondsRemaining + "s"
                  : "completato"}
                {shouldHaveUpperBodyImmersed ? "" : " - attendere"})
              </div>
            )}
            <div className="flex justify-center">
              Tempo totale {zeroPad(immersionTime.minutes)}:
              {zeroPad(immersionTime.seconds)}
            </div>
            {showStartButton && (
              <button
                className={"rounded p-2 mt-2 bg-blue-200"}
                onClick={onStartClicked}
              >
                {timerState.state === "NOT_STARTED" ? "Avvia" : "Riprendi"}
              </button>
            )}
            {showPauseButton && (
              <button
                className="rounded p-2 mt-2 bg-red-200"
                onClick={onPauseClicked}
              >
                Interrompi
              </button>
            )}
          </div>
        </div>
      </div>
    </CollapseCard>
  )
}

TimerSection.propTypes = {
  transitionToNextStage: PropTypes.func,
  currentSession: PropTypes.object,
  timerRef: PropTypes.object,
  stopInterval: PropTypes.func
}

export default TimerSection
