import { atom } from "jotai"
import { formatDate } from "../../../utils"

export const PUBLIC_PROTOCOL_FORM_INITIAL_STATE = {
  height: 0,
  weight: 0,
  gender: "gender-m",
  dateOfBirth: formatDate(new Date()),
  roomTemperature: 25,
  waterTemperature: 10,
  userType: "",
  therapyType: "",
  thermalComfort: "",
  immersionType: ""
}

export const publicProtocolFormStateAtom = atom({
  ...PUBLIC_PROTOCOL_FORM_INITIAL_STATE
})

export const isSubmittingPublicProtocolAtom = atom(false)

export const publicProtocolDataIsComplete = (protocolData) => {
  if (!protocolData) return false
  if (
    protocolData.height > 0 &&
    protocolData.weight > 0 &&
    protocolData.roomTemperature > 0 &&
    protocolData.waterTemperature > 0 &&
    protocolData.gender.length > 0 &&
    protocolData.therapyType.length > 0 &&
    protocolData.thermalComfort.length > 0 &&
    protocolData.userType.length > 0 &&
    protocolData.immersionType.length > 0
  )
    return true
  return false
}

export const canComputePublicProtocolAtom = atom((get) => {
  const protocolFormState = get(publicProtocolFormStateAtom)
  return publicProtocolDataIsComplete(protocolFormState)
})
