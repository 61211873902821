import PropTypes from "prop-types"
import { CheckBadgeIcon } from "@heroicons/react/24/solid"

const SessionCompletedWidget = ({ createNewSession }) => {
  return (
    <div className="flex justify-center mt-10 mb-10">
      <div className="card w-96 bg-base-100 shadow-xl">
        <div className="flex justify-center mt-4">
          <CheckBadgeIcon className="w-16 text-success" />
        </div>

        <div className="card-body items-center text-center">
          <h2 className="card-title">Sessione Completata</h2>
          <p>La sessione QRYO è completata e salvata.</p>
          <button
            className="btn bnt-block btn-primary mt-4"
            onClick={() => createNewSession()}
          >
            Nuova Sessione
          </button>
        </div>
      </div>
    </div>
  )
}

SessionCompletedWidget.propTypes = {
  createNewSession: PropTypes.func
}

export default SessionCompletedWidget
