import { UserIcon } from "@heroicons/react/24/solid"
import { useAtom } from "jotai"
import {
  isLoggedInAtom,
  loggedInUserDataAtom,
  Logout,
  userMedicalCenterDataAtom
} from "../../components/auth/authentication"

const SessionPageStickyHeader = () => {
  const setIsLoggedIn = useAtom(isLoggedInAtom)[1]
  const logout = () => {
    Logout()
    setIsLoggedIn(false)
  }

  const [loggedInUserData] = useAtom(loggedInUserDataAtom)
  const [userMedicalCenterData] = useAtom(userMedicalCenterDataAtom)

  let centerTitle = "Terapia QRYO"
  if (userMedicalCenterData && userMedicalCenterData.name) {
    centerTitle = userMedicalCenterData.name
  }

  let loggedInNameDisplay = "Dottore"
  if (loggedInUserData && loggedInUserData.displayName) {
    loggedInNameDisplay = loggedInUserData.displayName
  }

  return (
    <div className="navbar bg-base-100 border-b border-gray-200 shadow-sm">
      <div className="flex-1">
        <a className="btn btn-ghost normal-case text-xl">{centerTitle}</a>
      </div>
      <div className="flex-none gap-2">
        <div className="invisible sm:visible">{loggedInNameDisplay}</div>
        <div className="dropdown dropdown-end">
          <label tabIndex={0} className="btn btn-ghost btn-circle avatar">
            <div className="w-10 rounded-full">
              <UserIcon />
            </div>
          </label>
          <ul
            tabIndex={0}
            className="mt-3 p-2 shadow menu menu-compact dropdown-content bg-base-100 rounded-box w-52"
          >
            <li className="visible sm:invisible sm:h-0">
              <h2>{loggedInNameDisplay}</h2>
            </li>
            <li>
              <a onClick={() => logout()}>Esci</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}
export default SessionPageStickyHeader
