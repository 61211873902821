import { atom } from "jotai"
import { atomWithStorage } from "jotai/utils"

export const IMMERSION_TIME_INITIAL_STATE = {
  minutes: 0,
  seconds: 0
}

export const TIMER_INITIAL_STATE = {
  minutesRemaining: 0,
  secondsRemaining: 0,
  state: "NOT_STARTED" // STARTED, PAUSED, COMPLETED
}

export const BODY_IMMERSION_TIMES = {
  lowerBodyTime: 0,
  upperBodyTime: 0
}

export const immersionTimeAtom = atom({ ...IMMERSION_TIME_INITIAL_STATE })
export const bodyImmersionTimeAtom = atom({ ...BODY_IMMERSION_TIMES })
export const timerStateAtom = atomWithStorage("timerStateAtom", {
  ...TIMER_INITIAL_STATE
})
