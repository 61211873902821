import { RingLoader } from "react-spinners"

const SessionPageDataLoader = () => {
  return (
    <div className="flex justify-center mt-10">
      <div className="card w-96 bg-base-100 shadow-xl">
        <div className="flex justify-center mt-10">
          <RingLoader color={"#f07d00"} />
        </div>

        <div className="card-body items-center text-center">
          <h2 className="card-title">Caricamento in corso</h2>
          <p>L&apos;applicazione sta caricando i dati</p>
        </div>
      </div>
    </div>
  )
}

export default SessionPageDataLoader
