export const convertSecondsToMinAndSecs = (totalSeconds) => {
  const minutes = Math.floor(totalSeconds / 60)
  const seconds = totalSeconds - minutes * 60
  return {
    minutes,
    seconds
  }
}

export const convertMinAndSecsToSeconds = ({ minutes, seconds }) => {
  return minutes * 60 + seconds
}

export const computedImmersionTimeAtom = (patientData, protocolData) => {
  let immersionTime = 0

  const baseImmersionTime = 1 * 60

  // scale 10 minutes of immersion based on therapy type
  if (patientData.therapType === "recupero_post_esercizio") {
    immersionTime = 1.0 * baseImmersionTime
  } else if (patientData.therapType === "recupero_post_infortunio") {
    immersionTime = 0.8 * baseImmersionTime
  } else if (patientData.therapType === "recupero_post_operatorio") {
    immersionTime = 0.5 * baseImmersionTime
  } else if (patientData.therapType === "precooling") {
    immersionTime = 0.25 * baseImmersionTime
  } else if (patientData.therapType === "dolori_cronici") {
    immersionTime = 0.75 * baseImmersionTime
  } else if (patientData.therapType === "miglioramento_del_sonno") {
    immersionTime = 0.5 * baseImmersionTime
  } else if (patientData.therapType === "dimagrimento") {
    immersionTime = 1.2 * baseImmersionTime
  }

  // todo: scale for fat body percentage

  // scale for tank temperature
  let scalingForTankTemperature = 1.0
  if (protocolData.waterTemperature > 7) {
    scalingForTankTemperature = 1.2
  }

  // round up the final seconds scaled by tank temperature
  const finalImmersionTime = Math.ceil(
    immersionTime * scalingForTankTemperature
  )
  // convert to JS object {minutes, seconds}
  return convertSecondsToMinAndSecs(finalImmersionTime)
}
