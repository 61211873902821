import { atom } from "jotai"
import { formatDate } from "../../../utils"

export const PATIENT_DATA_FORM_INITIAL_STATE = {
  name: "",
  dateOfBirth: formatDate(new Date()),
  weight: 0,
  height: 0,
  gender: "",
  percentFatMass: 9,
  percentLeanMass: 0
  // skinType: "",
}

export const patientDataFormStateAtom = atom({
  ...PATIENT_DATA_FORM_INITIAL_STATE
})

// when enabled triggers the display of a MODAL to confirm
// resetting the patient data form
export const patientDataFormResetModalVisibleAtom = atom(false)

export const isSubmittingPatientDataAtom = atom(false)

export const patientDataIsComplete = (patientData) => {
  if (!patientData) return false
  if (patientData.name.length === 0) return false
  if (patientData.weight === 0) return false
  if (patientData.height === 0) return false
  if (patientData.gender.length === 0) return false
  if (patientData.percentFatMass === 0) return false
  if (patientData.percentLeanMass === 0) return false
  // if (patientData.skinType.length === 0) return false
  return true
}

export const canSubmitPatientDataAtom = atom((get) => {
  const patientDataFormState = get(patientDataFormStateAtom)
  return patientDataIsComplete(patientDataFormState)
})
