export default function PublicSessionPageNoEmail() {
  return (
    <div className="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <img
          className="mx-auto h-16 w-auto"
          src="/assets/qryo_logo_blue_alpha.png"
          alt="QRYO Logo - Performance Forever"
        />
        <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
          Calcolo Protocollo Qryo
        </h2>
      </div>

      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
          <p>
            Per accedere alla piattaforma Qryo è necessario usare il link
            ricevuto per email.
          </p>
          <p className="mt-4">
            Nel caso abbiate usato il link ma ricevete questo messaggio
            contattateci direttamente.
          </p>
          <a
            href={process.env.REACT_APP_PUBLIC_PROTOCOL_SIGNUP_URL}
            className="btn btn-block btn-primary mt-4"
          >
            Richiedi Nuovo Link Accesso
          </a>
        </div>
      </div>
    </div>
  )
}
