export class PublicProtocolData {
  constructor(
    age,
    weight,
    height,
    gender,
    dateOfBirth,
    roomTemperature,
    waterTemperature,
    immersionProtocolTime,
    userType,
    therapyType,
    thermalComfort,
    immersionType,
    bodyImmersionTimes,
    percentFatMass,
    bmi
  ) {
    this.age = age
    this.weight = weight
    this.height = height
    this.gender = gender
    this.dateOfBirth = dateOfBirth
    this.roomTemperature = roomTemperature
    this.waterTemperature = waterTemperature
    this.immersionProtocolTime = immersionProtocolTime
    this.userType = userType
    this.therapyType = therapyType
    this.thermalComfort = thermalComfort
    this.immersionType = immersionType
    this.bodyImmersionTimes = bodyImmersionTimes
    this.percentFatMass = percentFatMass
    this.bmi = bmi
  }
}

export const PublicProtocolDataConverter = {
  toFirestore: (protocolData) => {
    if (!protocolData) return null
    return {
      age: protocolData.age,
      weight: protocolData.weight,
      height: protocolData.height,
      gender: protocolData.gender,
      dateOfBirth: protocolData.dateOfBirth,
      roomTemperature: protocolData.roomTemperature,
      waterTemperature: protocolData.waterTemperature,
      immersionProtocolTime: protocolData.immersionProtocolTime,
      userType: protocolData.userType,
      therapyType: protocolData.therapyType,
      thermalComfort: protocolData.thermalComfort,
      immersionType: protocolData.immersionType,
      bodyImmersionTimes: protocolData.bodyImmersionTimes,
      percentFatMass: protocolData.percentFatMass,
      bmi: protocolData.bmi
    }
  },
  // This is unlikely to be used, have it for completeness
  fromFirestore: (snapshot, options) => {
    const data = snapshot.data(options)
    return PublicProtocolDataConverter.fromFirestoreObject(data)
  },
  fromFirestoreObject: (data) => {
    if (!data) {
      return null
    }
    return new PublicProtocolData(
      data.age,
      data.weight,
      data.height,
      data.gender,
      data.dateOfBirth,
      data.roomTemperature,
      data.waterTemperature,
      data.immersionProtocolTime,
      data.userType,
      data.therapyType,
      data.thermalComfort,
      data.immersionType,
      data.bodyImmersionTimes,
      data.percentFatMass,
      data.bmi
    )
  }
}
