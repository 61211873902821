import { useAtom } from "jotai"
import PropTypes from "prop-types"
import { forwardRef } from "react"

import { ArrowUturnLeftIcon } from "@heroicons/react/24/solid"
import DatePicker from "react-datepicker"

import CollapseCard from "../../../components/CollapseCard"
// import { SKIN_TYPES } from "../../../constants"
import {
  acceptInputNumbersOnly,
  formatDate,
  handleFloatOnBlurStateChange,
  handleNumericStateChange,
  handleTextStateChange,
  selectAllOnFocus
} from "../../../utils"
import { sectionPatientDataStateAtom } from "../session-ui-state"
import {
  patientDataFormResetModalVisibleAtom,
  patientDataFormStateAtom,
  isSubmittingPatientDataAtom,
  canSubmitPatientDataAtom,
  PATIENT_DATA_FORM_INITIAL_STATE
} from "./patient-data-ui-state"
import ConfirmPatientDataFormResetModal from "./ConfirmPatientDataFormResetModal"
import { CurrentSession } from "../../../models/session"
import { UpdateSession } from "../../../components/user/user"
import { toast } from "react-toastify"

const PatientDataSection = ({ transitionToNextStage }) => {
  const [patientDataFormState, setPatientDataFormState] = useAtom(
    patientDataFormStateAtom
  )
  const [sectionState, setSectionState] = useAtom(sectionPatientDataStateAtom)

  const setFormResetModalVisible = useAtom(
    patientDataFormResetModalVisibleAtom
  )[1]

  const [isSubmittingPatientData, setIsSubmittingPatientData] = useAtom(
    isSubmittingPatientDataAtom
  )

  const [canSubmitPatientData] = useAtom(canSubmitPatientDataAtom)

  const [currentSession] = useAtom(CurrentSession)

  const handleCollapsedChanged = (event) => {
    setSectionState({ ...sectionState, collapsed: event.target.checked })
  }

  const handlePatientDataServerResponse = (error) => {
    console.log("HANDLING PATIENT DATA RESPONSE FROM SERVER", error)
    setIsSubmittingPatientData(false)
    transitionToNextStage()

    if (error) {
      toast("Errore nel salvataggio dati paziente", { type: "error" })
    } else {
      toast("Dati paziente salvati", { type: "info" })
    }
  }

  const handleSubmit = async (event) => {
    event.preventDefault()
    if (canSubmitPatientData && currentSession) {
      console.log("SUBMITTING PATIENT DATA TO SERVER", currentSession)
      setIsSubmittingPatientData(true)
      const error = await UpdateSession(currentSession.uuid, {
        patientData: {
          ...patientDataFormState
        }
      })
      handlePatientDataServerResponse(error)
    }
  }

  const handleFloatOnBlur = (fieldName) => {
    handleFloatOnBlurStateChange(
      fieldName,
      patientDataFormState,
      setPatientDataFormState
    )
  }

  const handleNumericChange = (event) => {
    handleNumericStateChange(
      event,
      patientDataFormState,
      setPatientDataFormState
    )
  }

  const handleChange = (event) => {
    handleTextStateChange(event, patientDataFormState, setPatientDataFormState)
  }

  const handleDOBChange = (newDate) => {
    patientDataFormState.dateOfBirth = formatDate(newDate)
    setPatientDataFormState({ ...patientDataFormState })
  }

  const resetForm = () => {
    setPatientDataFormState({ ...PATIENT_DATA_FORM_INITIAL_STATE })
  }

  if (sectionState.visible === false) {
    return <></>
  }

  // const skinTypeOptions = SKIN_TYPES.map((skinType, index) => {
  //   const value = skinType.toLowerCase().replace(" ", "_")
  //   return (
  //     <option key={index} value={value}>
  //       {skinType}
  //     </option>
  //   )
  // })
  const date = new Date()
  // compute the years difference between the current date and the birth date
  const age =
    date.getFullYear() -
    new Date(patientDataFormState.dateOfBirth).getFullYear()

  const CustomDateInput = forwardRef(({ value, onClick }, ref) => (
    <input
      type="text"
      ref={ref}
      onClick={onClick}
      defaultValue={value}
      className="input input-bordered"
      disabled={sectionState.completed}
      readOnly
    />
  ))

  CustomDateInput.displayName = "CustomDateInput"
  CustomDateInput.propTypes = {
    value: PropTypes.string,
    onClick: PropTypes.func
  }

  return (
    <CollapseCard
      isCollapsed={sectionState.collapsed}
      isCompleted={sectionState.completed}
      handleCollapsedChanged={handleCollapsedChanged}
      title={"Dati Paziente"}
    >
      <ConfirmPatientDataFormResetModal onConfirmReset={resetForm} />

      <form onSubmit={handleSubmit}>
        <div className="form-control">
          <label className="label">
            <span className="label-text">
              Nome del paziente (escludere cognome)
            </span>
          </label>
          <input
            id="name"
            name="name"
            type="text"
            required
            placeholder="Nome"
            onChange={handleChange}
            value={patientDataFormState.name}
            className="input input-bordered"
            disabled={sectionState.completed}
          />
        </div>
        <div className="form-control">
          <label className="label">
            <span className="label-text">Data di nascita ({age} anni)</span>
          </label>

          <DatePicker
            selected={new Date(patientDataFormState.dateOfBirth)}
            onChange={handleDOBChange}
            peekNextMonth
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
            customInput={<CustomDateInput />}
            locale="it"
            dateFormat="dd MMM yyyy"
          />
        </div>
        <div className="form-control">
          <label className="label">
            <span className="label-text">Peso (kg)</span>
          </label>
          <input
            id="weight"
            name="weight"
            type="number"
            required
            placeholder="kg"
            min={15}
            max={420}
            step={0.1}
            onChange={handleNumericChange}
            onBlur={handleFloatOnBlur}
            value={patientDataFormState.weight}
            className="input input-bordered"
            disabled={sectionState.completed}
            onFocus={selectAllOnFocus}
            onKeyPress={acceptInputNumbersOnly}
          />
        </div>
        <div className="form-control">
          <label className="label">
            <span className="label-text">Altezza (cm)</span>
          </label>
          <input
            id="height"
            name="height"
            type="number"
            required
            placeholder="cm"
            min={15}
            max={420}
            step={0.1}
            onChange={handleNumericChange}
            onBlur={handleFloatOnBlur}
            value={patientDataFormState.height}
            className="input input-bordered"
            disabled={sectionState.completed}
            onFocus={selectAllOnFocus}
            onKeyPress={acceptInputNumbersOnly}
          />
        </div>
        <div className="form-control">
          <label className="label">
            <span className="label-text">Genere</span>
          </label>
          <label className="label">
            <span className="label-text">Uomo</span>
            <input
              id="gender-m"
              name="gender"
              type="radio"
              required
              onChange={handleChange}
              value="gender-m"
              checked={patientDataFormState.gender === "gender-m"}
              disabled={sectionState.completed}
              className="radio"
            />
          </label>
          <label className="label">
            <span className="label-text">Donna</span>
            <input
              id="gender-f"
              name="gender"
              type="radio"
              required
              onChange={handleChange}
              value="gender-f"
              checked={patientDataFormState.gender === "gender-f"}
              disabled={sectionState.completed}
              className="radio"
            />
          </label>
        </div>
        <div className="form-control">
          <label className="label">
            <span className="label-text">Massa grassa (%)</span>
          </label>
          <input
            id="percentFatMass"
            name="percentFatMass"
            type="number"
            required
            placeholder="0%"
            min={4}
            max={35}
            step={0.01}
            onChange={handleNumericChange}
            onBlur={handleFloatOnBlur}
            value={patientDataFormState.percentFatMass}
            className="input input-bordered"
            disabled={sectionState.completed}
            onFocus={selectAllOnFocus}
            onKeyPress={acceptInputNumbersOnly}
          />
        </div>
        <div className="form-control">
          <label className="label">
            <span className="label-text">Massa magra (%)</span>
          </label>
          <input
            id="percentLeanMass"
            name="percentLeanMass"
            type="number"
            required
            placeholder="0%"
            min={0}
            max={100}
            step={0.01}
            onChange={handleNumericChange}
            onBlur={handleFloatOnBlur}
            value={patientDataFormState.percentLeanMass}
            className="input input-bordered"
            disabled={sectionState.completed}
            onFocus={selectAllOnFocus}
            onKeyPress={acceptInputNumbersOnly}
          />
        </div>
        {/* <div className="form-control">
          <label className="label">
            <span className="label-text">Tipologia pelle</span>
          </label>
          <select
            id="skinType"
            name="skinType"
            type="select"
            required
            onChange={handleChange}
            className="select select-bordered"
            disabled={sectionState.completed}
            value={patientDataFormState.skinType}
          >
            <option disabled value="">
              Scegliere tipologia pelle paziente
            </option>
            {skinTypeOptions}
          </select>
        </div>
        */}
        {!sectionState.completed && (
          <div className={"flex mt-4 gap-2"}>
            <button
              type="submit"
              className={
                "btn btn-block flex-1" +
                (canSubmitPatientData ? " btn-primary" : "") +
                (isSubmittingPatientData ? " loading" : "")
              }
              disabled={!canSubmitPatientData}
            >
              {isSubmittingPatientData
                ? "Salvataggio dati"
                : "Salva Dati Paziente"}
            </button>
            <div className="tooltip" data-tip="Cancella modulo">
              <button
                className="btn btn-circle btn-outline flex-none"
                onClick={(event) => {
                  event.preventDefault()
                  setFormResetModalVisible(true)
                }}
              >
                <ArrowUturnLeftIcon className="w-7" />
              </button>
            </div>
          </div>
        )}
      </form>
    </CollapseCard>
  )
}

PatientDataSection.propTypes = {
  transitionToNextStage: PropTypes.func,
  currentSession: PropTypes.object
}

export default PatientDataSection
