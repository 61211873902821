import { Navigate } from "react-router-dom"
import { useAtom } from "jotai"
import { isLoggedInAtom } from "../../components/auth/authentication"
import { URL_PATH_LOGIN } from "../../constants"

import SessionPageStickyHeader from "./SessionPageStickyHeader"
import { useEffect, useRef } from "react"
import {
  currentSessionStageAtom,
  isFetchingSessionDataAtom,
  sectionFeedbackStateAtom,
  sectionPatientDataStateAtom,
  sectionProtocolStateAtom,
  sectionTimerStateAtom
} from "./session-ui-state"
import SessionPageSections from "./SessionPageSections"
import SessionPageDataLoader from "./SessionPageDataLoader"
import {
  CreateNewSession,
  GetAllSessionData,
  GetUserData
} from "../../components/user/user"
import { getAuth } from "firebase/auth"
import { useAuthState } from "react-firebase-hooks/auth"
import app from "../../components/firebase/firebase"
import { CurrentSession } from "../../models/session"
import { PatientDataConverter } from "../../models/patient-data"
import {
  patientDataFormStateAtom,
  patientDataIsComplete,
  PATIENT_DATA_FORM_INITIAL_STATE
} from "./PatientDataSection/patient-data-ui-state"
import { ProtocolDataConverter } from "../../models/protocol-data"
import {
  protocolDataIsComplete,
  protocolFormStateAtom,
  PROTOCOL_FORM_INITIAL_STATE
} from "./ProtocolSection/protocol-ui-state"
import { toast } from "react-toastify"
import {
  bodyImmersionTimeAtom,
  BODY_IMMERSION_TIMES,
  immersionTimeAtom,
  IMMERSION_TIME_INITIAL_STATE,
  timerStateAtom,
  TIMER_INITIAL_STATE
} from "./TimerSection/timer-ui-state"
import { convertSecondsToMinAndSecs } from "./TimerSection/protocol-calculator"
import { isSessionCompleted } from "../../utils"
import {
  feedbackFormStateAtom,
  FEEDBACK_FORM_INITIAL_STATE
} from "./FeedbackSection/feedback-section-ui-state"

const auth = getAuth(app)

function SessionPage() {
  /*

    UI STATES -> NO SESSION DATA / LAST SESSION IS COMPLETED
      SHOW PLACEHOLDER WITH BUTTON TO CREATE A NEW PATIENT SESSION
      ON CREATE OF NEW SESSION -> UI with 4 steps + 4 sections appears
    
    IN SESSION MODE
      PATIENT SESSION DATA NOT SUBMITTED (this section is visible and enabled)
        On form submitted:
          UPDATE Session data
          Set this section disabled
          Set next section enabled
      PROTOCOL SECTION
      THERAPY SESSION COMPLETED
      FEEDBACK COMPLETED = session completed

  */

  const [isLoggedIn] = useAtom(isLoggedInAtom)

  const [isFetchingSessionData, setIsFetchingSessionData] = useAtom(
    isFetchingSessionDataAtom
  )
  const [user] = useAuthState(auth)
  const [currentSession, setCurrentSession] = useAtom(CurrentSession)

  const timerRef = useRef(null)

  const stopInterval = () => {
    if (timerRef.current) {
      clearInterval(timerRef.current)
    }
  }

  // FETCH session data
  const fetchSessionData = async () => {
    const userData = await GetUserData(user)
    const sessions = await GetAllSessionData(user.uid)
    console.log("sessions", sessions)
    let newSessionCreated = false
    if (
      sessions.length === 0 ||
      isSessionCompleted(sessions[sessions.length - 1])
    ) {
      const session = await CreateNewSession(
        user.uid,
        userData.medicalCenterUuid
      )
      console.log("created session", session)
      sessions.push(session)
      newSessionCreated = true
    }
    // TODO check if session is completed
    const currentSession = sessions[sessions.length - 1]
    setCurrentSession(currentSession)
    setIsFetchingSessionData(false)
    if (newSessionCreated) {
      toast("Nuova sessione caricata", { type: "info" })
    } else {
      toast("Sessione caricata", { type: "info" })
    }
  }

  useEffect(() => {
    // NOTE: last session data can be
    // a) not existing, user has created no session
    // b) with status = completed
    // c) with status = not completed / pending

    // in the cases of a, b -> we set local state to be a new empty session
    // and load a blank session page

    // in the cases of c -> se set loaded session into local state
    // and update local UI to have all populated fields as needed
    if (!user) {
      return
    }
    console.log("FETCHING LAST SESSION DATA")
    setIsFetchingSessionData(true)
    fetchSessionData()
  }, [user])

  const [, setCurrentSessionStage] = useAtom(currentSessionStageAtom)
  const [, setPatientDataFormState] = useAtom(patientDataFormStateAtom)
  const [, setSectionPatientDataState] = useAtom(sectionPatientDataStateAtom)
  const [, setProtocolFormState] = useAtom(protocolFormStateAtom)
  const [, setSectionProtocolState] = useAtom(sectionProtocolStateAtom)
  const [, setImmersionTime] = useAtom(immersionTimeAtom)
  const [, setBodyImmersionTime] = useAtom(bodyImmersionTimeAtom)
  const [timerState, setTimerState] = useAtom(timerStateAtom)
  const [, setSectionTimerState] = useAtom(sectionTimerStateAtom)
  const [, setFeedbackFormState] = useAtom(feedbackFormStateAtom)
  const [, setSectionFeedbackState] = useAtom(sectionFeedbackStateAtom)

  useEffect(() => {
    if (isFetchingSessionData) return

    console.log("SessionPage:useEffect() load UI state")

    let sessionStageToActivate = "stage_patient_data"

    // convert patient data from session to a JS object (firestore)
    const patientData = PatientDataConverter.toFirestore(
      currentSession.patientData
    )
    const patientSectionState = {
      collapsed: false,
      completed: false,
      visible: true
    }
    if (patientData) {
      setPatientDataFormState(patientData)
    }
    if (patientDataIsComplete(patientData)) {
      // we can at least activate protocol stage
      sessionStageToActivate = "stage_protocol"
      // mark patient data section as completed
      patientSectionState.collapsed = true
      patientSectionState.completed = true
    }
    setSectionPatientDataState(patientSectionState)

    // convert PROTOCOL DATA from session to a JS object (firestore)
    const protocolData = ProtocolDataConverter.toFirestore(
      currentSession.protocolData
    )
    if (protocolData) {
      setProtocolFormState(protocolData)
    }

    const protocolSectionState = {
      collapsed: true,
      completed: false,
      visible: false
    }

    let immersionTimeState = { ...IMMERSION_TIME_INITIAL_STATE }
    let bodyImmersionTimeState = { ...BODY_IMMERSION_TIMES }

    if (protocolDataIsComplete(protocolData)) {
      sessionStageToActivate = "stage_timer"

      immersionTimeState = convertSecondsToMinAndSecs(
        protocolData.immersionProtocolTime
      )

      bodyImmersionTimeState = protocolData.bodyImmersionTimes
      // bodyImmersionTime.lowerBodyTime =
      // mark protocol section as completed
      protocolSectionState.completed = true
      protocolSectionState.visible = true
    } else if (sessionStageToActivate === "stage_protocol") {
      // previous section is complete, expand this one and make it visible
      protocolSectionState.collapsed = false
      protocolSectionState.visible = true
    }
    setImmersionTime(immersionTimeState)
    setBodyImmersionTime(bodyImmersionTimeState)
    setSectionProtocolState(protocolSectionState)

    // TIMER SESSION
    const timerData = timerState
    const timerSectionState = {
      collapsed: true,
      completed: false,
      visible: false
    }
    if (sessionStageToActivate === "stage_timer") {
      // check if timer stage is completed -> if complete, then move on
      const isSessionCompleted = currentSession.sessionLog.find((log) => {
        if (log.status === "COMPLETED") return true
        return false
      })
      timerSectionState.visible = true
      if (isSessionCompleted) {
        sessionStageToActivate = "stage_feedback"
        timerSectionState.collapsed = true
        timerSectionState.completed = true
        timerData.state = "COMPLETED"
      } else {
        console.log(
          "TODO: compute timer section remaining time state (should be loaded from state)"
        )
        if (
          timerState.minutesRemaining === 0 &&
          timerState.secondsRemaining === 0
        ) {
          timerState.minutesRemaining = immersionTimeState.minutes
          timerState.secondsRemaining = immersionTimeState.seconds
        }
        // otherwise compute remaining and status
        timerSectionState.collapsed = false
        timerSectionState.completed = false
      }
    }
    setSectionTimerState(timerSectionState)
    setTimerState(timerData)

    const feedbackSectionState = {
      collapsed: true,
      completed: false,
      visible: false
    }

    if (sessionStageToActivate === "stage_feedback") {
      feedbackSectionState.collapsed = false
      feedbackSectionState.visible = true
    }
    setSectionFeedbackState(feedbackSectionState)

    // final state setters: update current stage
    setCurrentSessionStage(sessionStageToActivate)
  }, [currentSession, isFetchingSessionData])

  const resetSession = () => {
    setCurrentSessionStage("stage_patient_data")

    // patient reset
    setSectionPatientDataState({
      collapsed: false,
      completed: false,
      visible: true
    })
    setPatientDataFormState({ ...PATIENT_DATA_FORM_INITIAL_STATE })

    // protocol reset
    setSectionProtocolState({
      collapsed: true,
      completed: false,
      visible: false
    })
    setProtocolFormState({ ...PROTOCOL_FORM_INITIAL_STATE })

    // timer reset
    setSectionTimerState({
      collapsed: true,
      completed: false,
      visible: false
    })
    setTimerState({ ...TIMER_INITIAL_STATE })

    // feedback reset
    setSectionFeedbackState({
      collapsed: true,
      completed: false,
      visible: false
    })
    setFeedbackFormState({ ...FEEDBACK_FORM_INITIAL_STATE })
  }

  const createNewSession = async () => {
    resetSession()
    setIsFetchingSessionData(true)
    fetchSessionData()
  }

  const cancelSession = async () => {
    // when this is called, we have already submitted session cancelled
    // feedabck
    toast("Sessione annullata", { type: "success" })
    await createNewSession()
  }

  // Refresh detection
  useEffect(() => {
    const unloadCallback = (event) => {
      event.preventDefault()
      event.returnValue = ""
      return ""
    }

    window.addEventListener("beforeunload", unloadCallback)
    return () => window.removeEventListener("beforeunload", unloadCallback)
  }, [])

  if (!isLoggedIn) {
    return <Navigate to={URL_PATH_LOGIN} replace={true} />
  }

  return (
    <div className="h-screen flex">
      {/* Content area */}
      <div className="flex-1 flex flex-col overflow-hidden">
        <SessionPageStickyHeader />
        {/* Main content */}
        <div className="h-full flex-1 flex items-stretch overflow-hidden">
          <main className="flex-1 overflow-y-auto bg-base-200">
            {isFetchingSessionData && <SessionPageDataLoader />}
            {!isFetchingSessionData && (
              <SessionPageSections
                createNewSession={createNewSession}
                cancelSession={cancelSession}
                timerRef={timerRef}
                stopInterval={stopInterval}
              />
            )}
          </main>
        </div>
      </div>
    </div>
  )
}

export default SessionPage
