import PropTypes from "prop-types"

const StepIndicator = ({ isPrimary, stepName }) => {
  return (
    <li className={"step" + (isPrimary ? " step-primary" : "")}>{stepName}</li>
  )
}

StepIndicator.propTypes = {
  isPrimary: PropTypes.bool,
  stepName: PropTypes.string
}

const SessionStepIndicator = ({
  currentSessionStageName,
  sessionStages,
  sessionStageNames
}) => {
  let currentStepNum = 0
  if (currentSessionStageName in sessionStages) {
    currentStepNum = sessionStages[currentSessionStageName]
  }

  const steps = Object.keys(sessionStages).map((stageId, index) => {
    if (stageId === "stage_completed") return null
    const isPrimary = currentStepNum >= index
    const stepName = sessionStageNames[stageId]
    return (
      <StepIndicator key={index} isPrimary={isPrimary} stepName={stepName} />
    )
  })
  return (
    <div className="flex flex-1">
      <ul className="flex-1 steps mt-4 mb-4">{steps}</ul>
    </div>
  )
}

SessionStepIndicator.propTypes = {
  currentSessionStageName: PropTypes.string,
  sessionStages: PropTypes.object,
  sessionStageNames: PropTypes.object
}

export default SessionStepIndicator
